import { Link } from "react-router-dom";
import { useUser } from '../context/userContext';
import { useCallback, useEffect, useState } from "react";
import OffCanvas from "./OffCanvas";
import offersProto from '../protofiles/offers_grpc_web_pb';
import { useGrpc } from '../context/grpcContext';
import {useReceivedOffersContext,} from '../context/receivedOffersContext';
import EventBus from "../utils/eventBus";

const { GetOffersByUserIdRequest } = offersProto;

const Navbar = ({ user }) => {
  console.log('navbar user::: ', user);
  const {grpcClients, handleGrpcRequest} = useGrpc();
  const { handleLogout } = useUser();
  const {getContextMarkersWithOffers, newReceivedOfferStatus, newWinningOffer, setNewWinningOffer, myNewWinningOffer, setMyWinningOffer} = useReceivedOffersContext();


  const [isReceivedOffersVisible, setIsReceivedOffersVisible] = useState(false);
  const [isSentOffersVisible, setIsSentOffersVisible] = useState(false);

  const [receivedOffers, setReceivedOffers] = useState([]);
  const [sentOffers, setSentOffers] = useState([]);
  

  const grpcGetOffersByUserId = useCallback(
    async (markerOfferToHighlight) => {
      try {
        const request = new GetOffersByUserIdRequest();
        if (user?.userid) {
          request.setUserid(user.userid);
              
          const getOffersByUserIdCb = (error, offersResponse) => {
            if (error) {
              console.log('grpcGetOffersByUserId error:: ', error);
              throw error;
            } else {
              let {offersList} = offersResponse.toObject();
              console.log('grpcGetOffersByUserId initial offersList::: ', offersList);
              
              let offerIdToHightlight;
              if (markerOfferToHighlight?.offerId) {
                offerIdToHightlight = markerOfferToHighlight.offerId;
              }
  
              let offersSortedByMarker = [];
              offersList.forEach(offer => {
                const {marker: markerFromOffer, ...offerWithouMarker} = offer;
                const {id: offerMarkerId} = markerFromOffer;
  
                if (offerWithouMarker?.status === 'winner' && offerIdToHightlight && offerWithouMarker.id === offerIdToHightlight) {
                  offerWithouMarker.type = "winner";
                }
                const indexFound = offersSortedByMarker.findIndex(marker => marker.id === offerMarkerId);
  
                if ( indexFound > -1 ) {
                  offersSortedByMarker[indexFound] = {
                    ...offersSortedByMarker[indexFound],
                    offersList: [...offersSortedByMarker[indexFound].offersList, offerWithouMarker]
                  }
                }
                else {
                  const markerWithOffers = {
                    ...markerFromOffer,
                    offersList: [offerWithouMarker]
                  };
                  offersSortedByMarker.push(markerWithOffers)
                }
              });
              console.log('grpcGetOffersByUserId offersSortedByMarker::: ', offersSortedByMarker);
  
              setSentOffers(offersSortedByMarker);  
            }
          };
  
          handleGrpcRequest(grpcClients.offersClient, 'getOffersByUserId', request, true, {}, getOffersByUserIdCb)
        }
      }
      catch(e) {
        console.log('grpcGetOffersByUserId errror::: ',e);
        throw e;
      }
    },
    [grpcClients.offersClient, handleGrpcRequest, user?.userid]
  );

  const handleShowReceivedOffers = async () => {
    setIsReceivedOffersVisible(() => !isReceivedOffersVisible);
    //center marker when clicking on received offers after newWinningOffer event
    setNewWinningOffer(false);
  };

  const handleShowSentOffers = async () => {
    console.log('useEffect newWinningOffer::: ', newWinningOffer);
    if (!isSentOffersVisible && !newWinningOffer) {
      await grpcGetOffersByUserId();
    }
    setIsSentOffersVisible(() => !isSentOffersVisible);
    //center marker when clicking on sent offers after newWinningOffer event
    setNewWinningOffer(false);
  };  

  const handleHideReceivedOffers = () => {
    setIsReceivedOffersVisible(() => !isReceivedOffersVisible)
  };

  const handleHideSentOffers = () => {
    setIsSentOffersVisible(() => !isSentOffersVisible); 
    setNewWinningOffer(false);
    setMyWinningOffer(false);
  };

  
  useEffect(() => {
      const markersAndOffers = getContextMarkersWithOffers();
      console.log('setting markersWithOffers setting markersWithOffers setting markersWithOffers', markersAndOffers);
      setReceivedOffers(markersAndOffers);

  }, 
  [ getContextMarkersWithOffers, newWinningOffer ]);

  useEffect(() => {
    const onRefreshSentOffersEvent = (data) => {
      console.log("Event received:", data);
      grpcGetOffersByUserId(data);
    };

    EventBus.on("refreshSentOffers", onRefreshSentOffersEvent);

    // Cleanup listener on unmount
    return () => {
      EventBus.off("refreshSentOffers", onRefreshSentOffersEvent);
    };
  }, [grpcGetOffersByUserId]);

  return (
    <div>
      <OffCanvas show={ isReceivedOffersVisible } handleClose={ handleHideReceivedOffers } name="Received Offers" payload={receivedOffers} placement="start" />
      <OffCanvas show={ isSentOffersVisible } handleClose={ handleHideSentOffers } name="Sent Offers" payload={sentOffers} placement="end" />
      <div className="navbar">
        <span className="logo">
          <Link className="link" to="/">
            Lama App
          </Link>
        </span>
        {user ? (
          <ul className="list">
            <li className="listItem" onClick={handleShowReceivedOffers}>
              { newReceivedOfferStatus && `*** `} Received Offers
            </li>
            <li className="listItem" onClick={handleShowSentOffers}>
              { newWinningOffer && myNewWinningOffer && `*** ` }Sent Offers
            </li>            
            <li className="listItem">
              <Link className="link" to="/profile">
                <img
                  src={user.profilepic}
                  alt="userPhoto"
                  className="avatar"
                />
              </Link>
            </li>
            <li className="listItem">{user.name}</li>
            <li className="listItem" onClick={handleLogout}>
              Logout
            </li>
          </ul>
        ) : (
          <Link className="link" to="login">
            Login
          </Link>
        )}
      </div>
    </div>
  );
};

export default Navbar;
