/* global google */
import React, {useState, useEffect, Fragment, useCallback} from 'react';
import { GoogleMap, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import useMapsJsApiLoader from '../context/mapsJsApiLoader';
import { maps } from '../config.js';
import { convertFromUnixTimeStamp } from '../utils/timeConverter';
import { useUser } from '../context/userContext.js'
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { FormControl, Placeholder } from 'react-bootstrap';
import isPromise from '../utils/isPromise.js';
import "../App.css";
import { useGrpc } from '../context/grpcContext';
import { useReceivedOffersContext } from '../context/receivedOffersContext.js';
import { useSearchParams, useNavigate } from "react-router-dom";
import VehicleDetails from '../components/VehicleDetails';
import markersProto from '../protofiles/markers_grpc_web_pb';
import offersProto from '../protofiles/offers_grpc_web_pb';
import vehiclesProto from '../protofiles/vehicles_grpc_web_pb';
import ChooseVehicle from '../components/ChooseVehicle';

const { GetVehiclesByUserIdRequest, VehicleRequest } = vehiclesProto;
const { Empty, /*MarkersResponse, MarkerResponse, locationObj*/ } = markersProto;
const {OfferRequest, GetOffersStatsForMarkerIdByUserIdRequest} = offersProto;

const { redPinIcon, greenPinIcon, mapCenteredToLocation } = maps;
const mapContainerStyle = {
  // width: '100vw',
  height: '100vh',
};

const InfoWindowContent = ({drawPolylineHandler, hidePolyLineHandler, handleOfferAmount, addOffer, markerData, isNewOfferAdded, vehicles=[]}) => {
  const {getUser} = useUser();  
  const user = getUser();
  // console.log('InfoWindowContent getUser()::: ', user);
  const grpcStuff= useGrpc();
  console.log('markerData.isInfoWindowOpen::: ', markerData.isInfoWindowOpen);
  console.log('markerData::: ', markerData);
  const { id, windowContent, userid: markerUserId/* isNnewMarker */ ,offerAmount, offerButtonState} = markerData;  
  if (!windowContent.hasCar) {
    vehicles = vehicles.map(v => {
      if (v.seatsno < windowContent.passangersNumber + 1) {
        v.disabled = true;
      }      
      return v;
    });
  }
  const [markerOffersStats, setMarkerOffersStats] = useState(null);
  const [showPlan, setShowPlan] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  //disable AddOffer button by setting an incorect value for offerAmount, instead of creating other 
  // !windowContent.hasCar && !selectedVehicle && ( handleOfferAmount({target: {value: ""}}) );

  useEffect(() => { 
    // console.log('newOffer::: ', newOffer);
    let grpcCallStatus;
    async function fetchMarkerOffersStats(markerId, userId, grpcStuff, callback) {
      try {
        const {grpcClients, handleGrpcRequest} = grpcStuff;

        const getOffersStatsForMarkerIdByUserIdRequest = new GetOffersStatsForMarkerIdByUserIdRequest();
        getOffersStatsForMarkerIdByUserIdRequest.setUserid(userId);
        getOffersStatsForMarkerIdByUserIdRequest.setMarkerid(markerId.toString());
    
        const getOffersStatsForMarkerIdByUserIdCb= (error, offerStatsResponse) => {
          if (error) {
            console.log('getOffersStatsForMarkerIdByUserId error:: ', error);
            throw error;
          } else {
            const { offersstats: offerStats } = offerStatsResponse.toObject();
            console.log('offerStats response::: ', offerStats);
            const {offerscountformarker: offersCountForMarker, yourbestoffer: yourBestOffer, rank} = offerStats;
            callback({offersCountForMarker, yourBestOffer, rank});
          }
        };
    
        const grpcCallStatus = handleGrpcRequest(grpcClients.offersClient, 'getOffersStatsForMarkerIdByUserId', getOffersStatsForMarkerIdByUserIdRequest, true, {}, getOffersStatsForMarkerIdByUserIdCb);
        return grpcCallStatus;
      }
      catch(e) {
        console.log('useEffect fetchMarkerOffersStats error:: ', e);
      }
    }

    grpcCallStatus = fetchMarkerOffersStats(id, user.userid, grpcStuff, setMarkerOffersStats);

    return(async () => {
      if (isPromise(grpcCallStatus)) {
        console.log('grpcCallStatus InfoWindowContent useEfffect::: ', grpcCallStatus);
        try {
          grpcCallStatus = await grpcCallStatus;
          grpcCallStatus.cancel();
        }
        catch(e) {
          throw new Error(`grpcCallStatus is not a promise, ${e.message}`);
        }
      }
    });

  }, [isNewOfferAdded, id, user?.userid, grpcStuff]);

  const onVehicleSelect = (vid) =>{
    console.log('InfoWindowContent onVehicleSelect()::: ', vid);
    setSelectedVehicle(vid);
  }




  return (
    windowContent && <div>
      <p>Name: {windowContent.name}</p>
      <p>Type: {windowContent.type}</p>
      <p>Has car: {windowContent.hasCar ? 'Yes' : 'No'}</p>
      <p>Passangers number: {windowContent.passangersNumber}</p>
      { !windowContent.hasCar && <ChooseVehicle vehicles={vehicles} showAddButton={false} deleteEnabled={false} onVehicleSelect={onVehicleSelect}/> }
      
      {      
        windowContent.hasCar && 
        <VehicleDetails 
          model={ windowContent.vehicles.model }
          type={ windowContent.vehicles.type }
          plate={ windowContent.vehiclesplate } 
          seatsNo={ windowContent.vehicles.seatsno }
        />
      }

      <p>Pick up from {windowContent.locationAddress}</p>
      <p>Stops: {windowContent.stopsNumber}</p>
      <p>Should arrive to {windowContent.destinationAddress} on : {windowContent.arrivalTimeDate}</p>
      <div className="container">
        <div className="row">
          <div className="col-md-auto">
            <Button variant="primary" size="sm" onClick={ (e) => {
              setShowPlan(() => !showPlan);
              !showPlan ? drawPolylineHandler(id) : hidePolyLineHandler()
            }}>
              {!showPlan ? 'Show plan' : 'Hide Plan'}
            </Button>
          </div>

          <div className="col-sm">
            {
              markerUserId !== user.userid
              && 
              <div className="addOfferInput_infoWindow">
                <InputGroup className="mb-3" size="sm">
                  <FormControl value={offerAmount} placeholder="Enter a number" aria-label="Number" onChange={ (e) => {
                    console.log('e::: ', e);
                    return handleOfferAmount(e);
                  }} />
                </InputGroup>
              </div>  
            }

          </div>  
                                                                      
          <div className="col-md-auto">
          {
            markerUserId !== user.userid
              &&                                                                 
            <Button disabled={offerButtonState} variant="secondary" size="sm" onClick={ 
              async (e) => {
                const offerData = {
                  ...markerData,
                };
                !windowContent.hasCar && selectedVehicle && ( offerData['selectedVehicle'] = selectedVehicle) ;
                windowContent.hasCar  && ( offerData['selectedVehicle'] = windowContent.vehicles) ;
                await addOffer(offerData);
              }
            }>
              Add offer
            </Button>
          }
          </div>
        </div>
        {
          markerUserId !== user.userid
            && 
          <div className="row">
            <div className="col-md-auto">
              { markerOffersStats ? <span>Offers count: { markerOffersStats.offersCountForMarker }</span> : <Placeholder xs="12"/> }
            </div>
            <div className="col-sm">
              { markerOffersStats?.offersCountForMarker > 0 ? <span>Your best offer: { markerOffersStats.yourBestOffer }</span> : (markerOffersStats?.offersCountForMarker === 0 ? <Placeholder xs="12"/> : <span></span>) }
            </div>
            <div className="col-md-auto">
              { markerOffersStats?.offersCountForMarker > 0 ? <span>Rank: { markerOffersStats.rank }</span> : (markerOffersStats?.offersCountForMarker === 0 ? <Placeholder xs="12"/> : <span></span>) }
            </div>
          </div>
        }
      </div>
  </div>
  );
};

const MapPage = ({markersData}) => {
  const {getUser} = useUser();  
  const user = getUser();

  const { newWinningOffer } = useReceivedOffersContext();

  const { isLoaded, /* loadError */ } = useMapsJsApiLoader();    
  const [map, setMap] = useState(null);
  const [isInfoWindowOpen, setIsOpenInfoWindow] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [path, setPath] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const {grpcClients, handleGrpcRequest} = useGrpc();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const defaultMarkersData = [];

  if (markersData && markersData.length) {
      defaultMarkersData.push(...markersData);
  }

  const [allMarkersData, setAllMarkersData] = useState([]);
  const [offerAmount, setOfferAmount] = useState(0);
  const [offerButtonState, setOfferButtonState] = useState(false);
  const [isNewOfferAdded, setIsNewOfferAdded] = useState(0);
  const [lastQueryMarker, setLastQueryMarker] = useState(null);

    useEffect(
      () => {
        let grpcCallStatus;

        async function getVehicles(){
          try {
              if (user?.userid) {
                  const request = new GetVehiclesByUserIdRequest();
                  request.setUserid(user.userid);

                  const getVehiclesCb = (error, response) => {
                    if (error) {
                      console.log('getVehicles() error:: ', error);
                      throw error;
                    } else {
                      const {vehiclesList} = response.toObject();
                      console.log('getVehiclesCb() vehicles response::: ', vehiclesList);
                      setVehicles(vehiclesList);
    
                    }
                  };
        
                  grpcCallStatus = handleGrpcRequest(grpcClients.vehiclesClient, 'getVehiclesByUserId', request, true, {}, getVehiclesCb);
              }
          }
          catch(e) {
            console.log('errror::: ',e);
          }
        }

        getVehicles();

        return(() => {
          console.log('getVehiclesByUserId grpcCall status::: ', grpcCallStatus);
          if (grpcCallStatus) {
            grpcCallStatus.cancel();
          }
        });
      },
      [
          grpcClients?.vehiclesClient, handleGrpcRequest, user?.userid
      ]
  )

  useEffect(() => {
      let grpcCallStatus;

      async function grpcGetMarkers(){
        try {
          const emptyRequest = new Empty();

          const getAllMarkersCb = (error, markersResponse) => {
            if (error) {
              console.log('GetAllMarkers error:: ', error);
              throw error;
            } 
            else {
              let {markersList} = markersResponse.toObject();
              console.log('initial markersList::: ', markersList);
              markersList = markersList.map(marker => {
                return {
                  ...marker,
                  windowContent: {
                    name: marker.username,
                    type: marker.type,
                    hasCar: marker.hascar,
                    vehicles: marker.hascar ? marker.vehicles : null,
                    passangersNumber: marker.passangersno,
                    stopsNumber: marker.stopsList.length,
                    arrivalTimeDate: convertFromUnixTimeStamp(marker.arrivaldatetime),
                    locationAddress: marker.location.address,
                    destinationAddress: marker.destination.address
                  }
                  ,
                  icon: marker.userid === user.userid ? greenPinIcon : redPinIcon,
                  isNnewMarker: false
                }
              })

              console.log('markersList::: ', markersList);
              markersList = adjustPositions(markersList);
              setAllMarkersData([ ...markersList]);
            }
          };

          grpcCallStatus = handleGrpcRequest(grpcClients.markersClient, 'getAllMarkers', emptyRequest, true, {}, getAllMarkersCb);
        }
        catch(e) {
          console.log('errror::: ',e);
        }
      }

      grpcGetMarkers();

      return(() => {
        console.log('grpcCall status::: ', grpcCallStatus);
        if (grpcCallStatus) {
          grpcCallStatus.cancel();
        }
      });
    },
    [
      user?.userid, 
      grpcClients?.markersClient, 
      handleGrpcRequest,
      newWinningOffer
    ]
  )

  //verificam daca infoWindow este descihisa pentru ca s-ar putea ca cineva sa o fi deschis cu url query 
  
  const clickOpenHandler = React.useCallback(
    (id, marker) => {
        // navigate(`/drive?m=${id}`, { shallow: true });
        setInfoWindowData(() => { return { id, marker } });
        setIsOpenInfoWindow((preisInfoWindowOpen) => {
          // console.log('preisInfoWindowOpen::: ', preisInfoWindowOpen);
          // console.log('isInfoWindowOpen::: ', isInfoWindowOpen);
            return true;
        });
    },
    []
    // [navigate]
  );

  const clickCloseHandler = useCallback(() => {   
    if (path) {
      path.setMap(null);
    }
    setIsOpenInfoWindow(false);
  },[path]);

  useEffect(() => {
      //iq req.query present , process it
      const checkQueryParams = () => {
        console.log('searchParams::: ', searchParams.getAll('m'), searchParams.get('m') );
        const markerId = searchParams.get('m');
        if (markerId) {
          const marker = allMarkersData.find(m => m.id === parseInt(markerId));
          console.log('useEffect marker:: ', marker);
          if (marker?.id && map) {
            // const { id, windowContent, location, icon } = marker;
    
            //if winnerOffer is the same with the marker we are looking at (the one in the query params) then we close the infoWindow because the marker is going to disapper (because is a winner marker)
            if (newWinningOffer?.markerId !== marker.id) {
              if (!isInfoWindowOpen) {
                //if we already "seen" this marker.id but we closed the its infoWindow, then we do not reopen when infoWindow
                if (lastQueryMarker === marker.id) {
                  console.log('lastQueryMarker === marker.id case');
                  clickCloseHandler();
                }
                else {
                  console.log('lastQueryMarker !!!!!!!!!!=== marker.id case');
                  clickOpenHandler(marker.id, marker);
                  setLastQueryMarker(marker.id);
                  //stop moving/jumping the map after re-render when newWinningOffer (less markers for markerClustered spiderify, so they change position)
                  !newWinningOffer && map.setCenter({"lat": marker.location.lat, "lng": marker.location.lng});
                  map.setZoom(20);
                }
              }
              else {
                clickCloseHandler();
                clickOpenHandler(marker.id, marker);
                setLastQueryMarker(marker.id);
                !newWinningOffer && map.setCenter({"lat": marker.location.lat, "lng": marker.location.lng});
                map.setZoom(20);
              }
    
            }
            else {
              clickCloseHandler();
            }
    
          }
        }      
      };

      checkQueryParams();

    }, 
    [allMarkersData, clickOpenHandler, searchParams, map, newWinningOffer, clickCloseHandler]
    // [allMarkersData, clickOpenHandler, searchParams, map, newWinningOffer, clickCloseHandler, isInfoWindowOpen, lastQueryMarker ]
  );

  const onMapLoad = React.useCallback(function callback(map) {  
    // map.setCenter({"lat":44.330177307128906,"lng":23.794879913330078});
    // map.setCenter(new google.maps.LatLng(44.330177307128906, 23.794879913330078));
    
    // const bounds = new google.maps.LatLngBounds();
    // const center = bounds.getCenter();
    // map.fitBounds(bounds);

    map.setZoom(13);

    setMap(map)
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);



  const hidePolyLineHandler = () => {
    if (path) {
      path.setMap(null);
    }      
  };

  const drawPolylineHandler = (markerId) => {  
    console.log('drawPolyline for markerId::: ', markerId);
    //compute new polyline between coordinates
    const foundMarker = allMarkersData.find(marker => marker.id === markerId);
    const {location, stopsList, destination} = foundMarker;
    const coordinates = [location, ...stopsList, destination];
    const plan = coordinates.map(c => {
      const {lat, lng} = c;
      return {
        lat,
        lng
      }
    });

    const drivePath = new google.maps.Polyline({
      path: plan,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    drivePath.setMap(map);

    //remove polyline path if any
    if (path) {
      path.setMap(null);
    }
    setPath(drivePath);
  };

  const addOffer = async (marker) => {
    console.log('MapPage addOffer() marker::: ', marker);
    const {id: markerId} = marker;
    const {userid: userId} = user;

    const offerRequest = new OfferRequest();
    offerRequest.setUserid(userId);
    offerRequest.setMarkerid(markerId.toString());
    offerRequest.setCarid(marker.vehicles?.id || marker.selectedVehicle || 0);
    offerRequest.setAmount(offerAmount);
    
    console.log(userId, markerId, offerAmount);
    const addOfferCb = (error, offersResponse) => {
      if (error) {
        throw error;
      }
      // const offer = offersResponse.toObject();
      // console.log('AddedOfffer::: ', offer);
      setIsNewOfferAdded( () => isNewOfferAdded + 1 );
    };

    handleGrpcRequest(grpcClients.offersClient, 'addOffer', offerRequest, true, {}, addOfferCb)

  };

  const handleOfferAmount = (event) => {
    const minimumAmount = 0;
    let inputValue = event.target.value;

    console.log(inputValue, typeof inputValue);      

    if (inputValue !== '' && !isNaN(inputValue) && inputValue >= minimumAmount) {
      inputValue = parseInt(inputValue);
      setOfferAmount(inputValue);
      setOfferButtonState(false);
    }
    else if (inputValue === '') {
      setOfferButtonState(true);        
      setOfferAmount(inputValue);
    }
    else {
      const parsedInputValue = parseInt(inputValue);
      if (isNaN(parsedInputValue) ) {
        setOfferAmount(0);
      }
      else {
        setOfferAmount(parseInt(inputValue));
      }
      setOfferButtonState(false);
    }
  };
  
  const adjustPositions = (markers) => {
    const OFFSET = 0.00005; // Adjust as needed
    const seen = {};

    const adjustedMarkers = markers.map((marker, i) => {
        const key = `${marker.location.lat},${marker.location.lng}`;

        if (seen[key]) {
            seen[key] += 1;
        } else {
            seen[key] = 1;
        }

        //we at least remove the "1" offeset added first time
        const offset = ( seen[key] - 1 ) * OFFSET;

        return {
            ...marker,
            location: {
                lat: marker.location.lat + offset,
                lng: marker.location.lng + offset,
            },
        };
    });

    // console.log('seen:: ',seen);
    return adjustedMarkers;
  };

  const formatMarkers = (clusterer, allMarkersData) => {

    const formatedMarkers = allMarkersData.map(markerData => {
      const { id, location, icon } = markerData;
      return (
        <Marker
          onClick={ () => { clickOpenHandler(id, markerData); } }
          icon={icon}
          position={location}
          key={id}
          clusterer={clusterer}
        />
      );
    });

    // console.log('formatedMarkers::: ', formatedMarkers);
    return formatedMarkers;

  };

  return (
      <div>
          {allMarkersData?.length} markers
          <div>
          {
            isLoaded 
            && 
            <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={15}
                    center={mapCenteredToLocation}
                    onClick={(e) => clickCloseHandler()} //close open InfoWindows
                    onLoad={onMapLoad}
                    onUnmount={onUnmount}
                >
                    {
                        allMarkersData?.length && (
                          <MarkerClusterer
                            gridSize={50} // Adjust the grid size to reduce overlapping
                            maxZoom={15}  // Prevent clustering when zoomed in close
                            averageCenter={true}
                          >
                            {
                              clusterer => {
                                return (
                                  <Fragment>
                                    {
                                      formatMarkers(clusterer, allMarkersData)
                                    }
                                  </Fragment>
                                );
                              }
                            }
                          </MarkerClusterer>
                        )
                    }
                    
                    {
                      (isInfoWindowOpen && infoWindowData?.id && allMarkersData.find(m=> m.id === infoWindowData?.id) ) && (
                      <InfoWindow
                          onCloseClick={() => clickCloseHandler()}
                          //+ 0.000005
                          position={{lat: +infoWindowData.marker.location.lat + 0.000015 , lng: +infoWindowData.marker.location.lng}}
                      >   
                          <InfoWindowContent 
                            drawPolylineHandler={drawPolylineHandler} 
                            hidePolyLineHandler={hidePolyLineHandler} 
                            handleOfferAmount={handleOfferAmount} 
                            addOffer={addOffer} 
                            markerData={{...infoWindowData.marker, offerAmount, offerButtonState}} 
                            isNewOfferAdded={isNewOfferAdded}
                            vehicles={vehicles}
                          />
                          {/* <div>{JSON.stringify(infoWindowData.marker)}</div> */}
                      </InfoWindow>
                      )
                    }
            </GoogleMap>
          }
          </div>
      </div>
  );
}

export default React.memo(MapPage);