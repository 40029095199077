import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { GoogleMap, Autocomplete } from '@react-google-maps/api';
import { maps } from '../config';
const { mapCenteredToLocation } = maps;
const inputStyle = {
  boxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `240px`,
  height: `32px`,
  padding: `0 12px`,
  borderRadius: `3px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `14px`,
  outline: `none`,
  textOverflow: `ellipses`,
  position: "absolute",
  left: "50%",
  marginLeft: "-120px"
}

const mapContainerStyle = {
    // width: '100vw',
    height: '50vh',
  };

function ModalWindow(props) {
  const {cb, type} = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let autocomplete = null;

  const onLoad = (ac) => {
    console.log('autocomplete ac: ', ac);
    autocomplete = ac;
    // autocomplete.addListener('place_changed', onPlaceChanged);
  }

  const onPlaceChanged = () => {

    console.log('onPlaceChanged someParam::: ', type);
    if (autocomplete !== null) {
      
      const place = autocomplete.getPlace();
      console.log('autocomplete.getPlace()::: ', place);

      const location = {
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      switch(type) {
        case 'setLocation': {
          console.log('setLocation type');

          console.log('setLocation location::: ', location);
          cb(location);
          break;
        }
        case 'setStops': {
          console.log('setStops type');
          const id = crypto.randomUUID()
          const {stops} = props;
          cb([...stops, {...location, id}]);
          break;
        }
        case 'setDestination': {
          console.log('setDestination type');
          cb(location);
          break;
        }
        default: 
          break;                
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }



  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {type}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I will not close if you click outside me. Do not even try to press
          escape key.
  
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={10}
              center={mapCenteredToLocation}
              // onClick={(e) => setAMarkerFromClickHandler(e)}
              // onLoad={onMapLoad}
              // onUnmount={onUnmount}
            > 

              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
                
              >
                <input
                  type="text"
                  placeholder="Customized your placeholder"
                  style={inputStyle}
                />
              </Autocomplete>

            </GoogleMap>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalWindow;