import React, { useState, useEffect } from 'react';
import { useUser } from '../context/userContext.js'
import { useGrpc } from '../context/grpcContext.js';
import ChooseVehicle from '../components/ChooseVehicle';
import AddCarForm from '../components/AddCarForm.jsx';
import vehiclesProto from '../protofiles/vehicles_grpc_web_pb';
import Button from 'react-bootstrap/Button';
import { useModalProvider } from '../context/ModalProviderContext.jsx';


const { GetVehiclesByUserIdRequest, VehicleRequest } = vehiclesProto;

const ProfilePage = () => {
    const { isAuthenticated, getUser }  = useUser();
    const user = getUser();
    const { grpcClients, handleGrpcRequest } = useGrpc();
    const { setModalConfig, showModal, hideModal } = useModalProvider();
    const [vehicles, setVehicles] = useState([]);

    const addVehicleHandler = (e) => {
        console.log('addVehicleHandler::: ', addVehicleHandler);
        e.preventDefault();
        setModalConfig( 
            { 
                header: "Add new vehicle",
                content: <>
                    <div>
                        <AddCarForm onSubmit={ saveVehicleHandler }/>
                    </div>
                </>
            } 
        );
        showModal();
    };

    const saveVehicleHandler = (e) => {
        
        try {
            console.log('saveVehicleHandler e::: ', e);
            const { type, model, plate, seatsNo } = e;
            
            const request = new VehicleRequest();
            request.setType(type);
            request.setModel(model);
            request.setPlate(plate);
            request.setSeatsno(seatsNo);

            const saveVehicleCb = (error, vehicleResponse) => {
                if (error) {
                    console.log('saveVehicleCb error:: ', error);
                    throw error;
                }
                console.log('saveVehicleHandler() vehicleResponse:: ', vehicleResponse);
                let vehicle = vehicleResponse.toObject();
                console.log('saved vehicle::: ', vehicle);
                setVehicles( [...vehicles, vehicle ] );
            };

            handleGrpcRequest(grpcClients.vehiclesClient, 'addVehicle', request, true, {}, saveVehicleCb);
            hideModal();
        }
        catch(e) {
            console.log('saveVehicleHandler errror::: ',e);
        }
    };    

    useEffect(
        () => {
          let grpcCallStatus;
    
          async function getVehicles(){
            try {
                if (user?.userid) {
                    const request = new GetVehiclesByUserIdRequest();
                    request.setUserid(user.userid);

                    const getVehiclesCb = (error, response) => {
                      if (error) {
                        console.log('getVehicles() error:: ', error);
                        throw error;
                      } else {
                        const {vehiclesList} = response.toObject();
                        console.log('getVehiclesCb() vehicles response::: ', vehiclesList);
                        setVehicles(vehiclesList);
      
                      }
                    };
          
                    grpcCallStatus = handleGrpcRequest(grpcClients.vehiclesClient, 'getVehiclesByUserId', request, true, {}, getVehiclesCb);
                }
            }
            catch(e) {
              console.log('errror::: ',e);
            }
          }
    
          getVehicles();
    
          return(() => {
            console.log('getVehiclesByUserId grpcCall status::: ', grpcCallStatus);
            if (grpcCallStatus) {
              grpcCallStatus.cancel();
            }
          });
        },
        [
            grpcClients?.vehiclesClient, handleGrpcRequest, user?.userid
        ]
    )

    return(
        <div>
            Profile page
            <div>
                <div>
                    <ChooseVehicle vehicles={vehicles} addVehicleHandler={addVehicleHandler} />       
                </div>
            </div>            
        </div>
    );
};
export default ProfilePage;