import { Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { useGrpc } from '../context/grpcContext';
import offersProto from '../protofiles/offers_grpc_web_pb';
const { WinningOfferRequest } = offersProto;

const PaymentPage = () => {
    const { grpcClients, handleGrpcRequest } = useGrpc();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const navigate = useNavigate();

    const markerId = searchParams.get('mid');
    const offerId = searchParams.get('oid');

    async function setWinningOffer(){
        try {
            console.log('setWinningOffer markerId, offerId::: ', markerId, offerId);
            const request = new WinningOfferRequest();
            request.setMarkerid(markerId);
            request.setOfferid(offerId);
            // console.log('grpcClients.markersClient::: ', grpcClients.markersClient);

            const setWinningOfferCb = (error, response) => {
                if (error) {
                    console.log('setWinningOfferCb error:: ', error);
                    throw error;
                } 
                else {
                    const answer = response.toObject();
                    console.log('setWinningOfferCb answer::: ', answer);
                    navigate('/');
                }
            };

            const grpcCallStatus = handleGrpcRequest(grpcClients.offersClient, 'setWinningOffer', request, true, {}, setWinningOfferCb);
            console.log('setWinningOffer grpcCallStatus::: ', grpcCallStatus);
        }
        catch(e) {
            console.log('errror::: ',e);
        }
      }

    const handlePayment = () => {
        setWinningOffer();
    };

    return(
        <div>
            <button onClick={handlePayment}>Pay</button>
        </div>
    );
};

export default PaymentPage;
