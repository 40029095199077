import {useModalProvider} from '../context/ModalProviderContext';
import GenericModal
 from './GenericModal';
 
const ModalDrawer = () => {
    const {modalConfig, modalVisibility, hideModal} = useModalProvider();
    const { header, content, handleSave } = modalConfig;
    // console.log('content::: ', content);
    // console.log('handleSave::: ', handleSave);
    // return(Object.keys(modalConfig).length > 0&& <div> { content} </div>);
    return (
        <GenericModal 
            show={modalVisibility} 
            handleClose={hideModal} 
            
            handleSave={ handleSave ? () => { handleSave(); hideModal(); } : null } 
            header={header}
            >    
                {content}
        </GenericModal>
    );
};

export default ModalDrawer;