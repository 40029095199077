/**
 * @fileoverview gRPC-Web generated client stub for markers
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protofiles/markers.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.markers = require('./markers_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.markers.MarkersServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.markers.MarkersServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.markers.MarkerRequest,
 *   !proto.markers.Empty>}
 */
const methodDescriptor_MarkersService_AddMarker = new grpc.web.MethodDescriptor(
  '/markers.MarkersService/AddMarker',
  grpc.web.MethodType.UNARY,
  proto.markers.MarkerRequest,
  proto.markers.Empty,
  /**
   * @param {!proto.markers.MarkerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.markers.Empty.deserializeBinary
);


/**
 * @param {!proto.markers.MarkerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.markers.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.markers.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.markers.MarkersServiceClient.prototype.addMarker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/markers.MarkersService/AddMarker',
      request,
      metadata || {},
      methodDescriptor_MarkersService_AddMarker,
      callback);
};


/**
 * @param {!proto.markers.MarkerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.markers.Empty>}
 *     Promise that resolves to the response
 */
proto.markers.MarkersServicePromiseClient.prototype.addMarker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/markers.MarkersService/AddMarker',
      request,
      metadata || {},
      methodDescriptor_MarkersService_AddMarker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.markers.Empty,
 *   !proto.markers.MarkersResponse>}
 */
const methodDescriptor_MarkersService_GetAllMarkers = new grpc.web.MethodDescriptor(
  '/markers.MarkersService/GetAllMarkers',
  grpc.web.MethodType.UNARY,
  proto.markers.Empty,
  proto.markers.MarkersResponse,
  /**
   * @param {!proto.markers.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.markers.MarkersResponse.deserializeBinary
);


/**
 * @param {!proto.markers.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.markers.MarkersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.markers.MarkersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.markers.MarkersServiceClient.prototype.getAllMarkers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/markers.MarkersService/GetAllMarkers',
      request,
      metadata || {},
      methodDescriptor_MarkersService_GetAllMarkers,
      callback);
};


/**
 * @param {!proto.markers.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.markers.MarkersResponse>}
 *     Promise that resolves to the response
 */
proto.markers.MarkersServicePromiseClient.prototype.getAllMarkers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/markers.MarkersService/GetAllMarkers',
      request,
      metadata || {},
      methodDescriptor_MarkersService_GetAllMarkers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.markers.MarkersWithOffersRequest,
 *   !proto.markers.MarkersWithOffersResponse>}
 */
const methodDescriptor_MarkersService_getMarkersWithOffersByUserId = new grpc.web.MethodDescriptor(
  '/markers.MarkersService/getMarkersWithOffersByUserId',
  grpc.web.MethodType.UNARY,
  proto.markers.MarkersWithOffersRequest,
  proto.markers.MarkersWithOffersResponse,
  /**
   * @param {!proto.markers.MarkersWithOffersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.markers.MarkersWithOffersResponse.deserializeBinary
);


/**
 * @param {!proto.markers.MarkersWithOffersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.markers.MarkersWithOffersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.markers.MarkersWithOffersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.markers.MarkersServiceClient.prototype.getMarkersWithOffersByUserId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/markers.MarkersService/getMarkersWithOffersByUserId',
      request,
      metadata || {},
      methodDescriptor_MarkersService_getMarkersWithOffersByUserId,
      callback);
};


/**
 * @param {!proto.markers.MarkersWithOffersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.markers.MarkersWithOffersResponse>}
 *     Promise that resolves to the response
 */
proto.markers.MarkersServicePromiseClient.prototype.getMarkersWithOffersByUserId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/markers.MarkersService/getMarkersWithOffersByUserId',
      request,
      metadata || {},
      methodDescriptor_MarkersService_getMarkersWithOffersByUserId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.markers.GetMyCompletedSolicitationsRequest,
 *   !proto.markers.GetMyCompletedSolicitationsResponse>}
 */
const methodDescriptor_MarkersService_GetMyCompletedSolicitations = new grpc.web.MethodDescriptor(
  '/markers.MarkersService/GetMyCompletedSolicitations',
  grpc.web.MethodType.UNARY,
  proto.markers.GetMyCompletedSolicitationsRequest,
  proto.markers.GetMyCompletedSolicitationsResponse,
  /**
   * @param {!proto.markers.GetMyCompletedSolicitationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.markers.GetMyCompletedSolicitationsResponse.deserializeBinary
);


/**
 * @param {!proto.markers.GetMyCompletedSolicitationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.markers.GetMyCompletedSolicitationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.markers.GetMyCompletedSolicitationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.markers.MarkersServiceClient.prototype.getMyCompletedSolicitations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/markers.MarkersService/GetMyCompletedSolicitations',
      request,
      metadata || {},
      methodDescriptor_MarkersService_GetMyCompletedSolicitations,
      callback);
};


/**
 * @param {!proto.markers.GetMyCompletedSolicitationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.markers.GetMyCompletedSolicitationsResponse>}
 *     Promise that resolves to the response
 */
proto.markers.MarkersServicePromiseClient.prototype.getMyCompletedSolicitations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/markers.MarkersService/GetMyCompletedSolicitations',
      request,
      metadata || {},
      methodDescriptor_MarkersService_GetMyCompletedSolicitations);
};


module.exports = proto.markers;

