/**
 * @fileoverview gRPC-Web generated client stub for vehicles
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protofiles/vehicles.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.vehicles = require('./vehicles_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.vehicles.VehiclesServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.vehicles.VehiclesServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vehicles.VehicleRequest,
 *   !proto.vehicles.VehicleResponse>}
 */
const methodDescriptor_VehiclesService_AddVehicle = new grpc.web.MethodDescriptor(
  '/vehicles.VehiclesService/AddVehicle',
  grpc.web.MethodType.UNARY,
  proto.vehicles.VehicleRequest,
  proto.vehicles.VehicleResponse,
  /**
   * @param {!proto.vehicles.VehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vehicles.VehicleResponse.deserializeBinary
);


/**
 * @param {!proto.vehicles.VehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vehicles.VehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vehicles.VehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vehicles.VehiclesServiceClient.prototype.addVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vehicles.VehiclesService/AddVehicle',
      request,
      metadata || {},
      methodDescriptor_VehiclesService_AddVehicle,
      callback);
};


/**
 * @param {!proto.vehicles.VehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vehicles.VehicleResponse>}
 *     Promise that resolves to the response
 */
proto.vehicles.VehiclesServicePromiseClient.prototype.addVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vehicles.VehiclesService/AddVehicle',
      request,
      metadata || {},
      methodDescriptor_VehiclesService_AddVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vehicles.GetVehiclesByUserIdRequest,
 *   !proto.vehicles.GetVehiclesByUserIdResponse>}
 */
const methodDescriptor_VehiclesService_GetVehiclesByUserId = new grpc.web.MethodDescriptor(
  '/vehicles.VehiclesService/GetVehiclesByUserId',
  grpc.web.MethodType.UNARY,
  proto.vehicles.GetVehiclesByUserIdRequest,
  proto.vehicles.GetVehiclesByUserIdResponse,
  /**
   * @param {!proto.vehicles.GetVehiclesByUserIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vehicles.GetVehiclesByUserIdResponse.deserializeBinary
);


/**
 * @param {!proto.vehicles.GetVehiclesByUserIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vehicles.GetVehiclesByUserIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vehicles.GetVehiclesByUserIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vehicles.VehiclesServiceClient.prototype.getVehiclesByUserId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vehicles.VehiclesService/GetVehiclesByUserId',
      request,
      metadata || {},
      methodDescriptor_VehiclesService_GetVehiclesByUserId,
      callback);
};


/**
 * @param {!proto.vehicles.GetVehiclesByUserIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vehicles.GetVehiclesByUserIdResponse>}
 *     Promise that resolves to the response
 */
proto.vehicles.VehiclesServicePromiseClient.prototype.getVehiclesByUserId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vehicles.VehiclesService/GetVehiclesByUserId',
      request,
      metadata || {},
      methodDescriptor_VehiclesService_GetVehiclesByUserId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vehicles.GetVehicleByIdRequest,
 *   !proto.vehicles.GetVehicleByIdResponse>}
 */
const methodDescriptor_VehiclesService_GetVehicleById = new grpc.web.MethodDescriptor(
  '/vehicles.VehiclesService/GetVehicleById',
  grpc.web.MethodType.UNARY,
  proto.vehicles.GetVehicleByIdRequest,
  proto.vehicles.GetVehicleByIdResponse,
  /**
   * @param {!proto.vehicles.GetVehicleByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vehicles.GetVehicleByIdResponse.deserializeBinary
);


/**
 * @param {!proto.vehicles.GetVehicleByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vehicles.GetVehicleByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vehicles.GetVehicleByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vehicles.VehiclesServiceClient.prototype.getVehicleById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vehicles.VehiclesService/GetVehicleById',
      request,
      metadata || {},
      methodDescriptor_VehiclesService_GetVehicleById,
      callback);
};


/**
 * @param {!proto.vehicles.GetVehicleByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vehicles.GetVehicleByIdResponse>}
 *     Promise that resolves to the response
 */
proto.vehicles.VehiclesServicePromiseClient.prototype.getVehicleById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vehicles.VehiclesService/GetVehicleById',
      request,
      metadata || {},
      methodDescriptor_VehiclesService_GetVehicleById);
};


module.exports = proto.vehicles;

