import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { 
  EmptyRequest 
} from '../protofiles/auth_pb'; // gRPC message classes
import { useGrpc } from './grpcContext';

const UserContext = createContext();

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw Error('useUser must be used within a UserProvider');
  }
  return context;
};

// #### isAuthenticated
//-1 loading
//0 loggedOut
//1 loggedIn

const UserProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(0);
  const [user, setUser] = useState(null);
  const {grpcClients, handleGrpcRequest} = useGrpc();
  const [isLoading, setIsLoading] = useState(true);

  const setUserIsLoggedIn = useCallback(
    (user) => {
      setIsAuthenticated(1);
      setUser(user);
    },
    []
  );

  const setUserIsLoggedOut = useCallback(
    () => {
      setIsAuthenticated(0);
      setUser(null);
    },
    []
  );

  const handleLogout = useCallback(
    () => {
      const request = new EmptyRequest();
  
      const logoutCb = (err, res) => {
        if (err) {
            console.error('Logout failed:', err);
            if (err.code === 16) {
              localStorage.removeItem('sessionid');
              setUserIsLoggedOut();                
            }
            return;
        }

        console.log('handleLogout response::: ', res.toObject());
        if (res?.getSuccess()) {
            localStorage.removeItem('sessionid');
            setUserIsLoggedOut();
        }
      };

      handleGrpcRequest(grpcClients.authClient, 'logout', request, true, {}, logoutCb);
    },
    [setUserIsLoggedOut, grpcClients.authClient, handleGrpcRequest]
  ); 

  const getUser = useCallback(
    () => {
      if (user) {
        return user;
      }
      return null;
    },
    [user]
  );
  
  useEffect(() => {
    let grpcCallStatus;

    const checkSession = (cb) => {
      try {
        const request = new EmptyRequest();
        const getSessionCb = (err, res) => {
          if (err || !res || !res.getAuthenticated()) {
              console.log('userContext checkSession() errr:: ', err);
              console.log('No active session');
              setIsLoading(false);                  
              return;
          }

          const sessionResponse = res.toObject();
          console.log('sessionResponse::: ', sessionResponse);
          setIsLoading(false);
          cb(sessionResponse);
        };

        grpcCallStatus = handleGrpcRequest(grpcClients.authClient, 'getSession', request, true, {}, getSessionCb);
        return grpcCallStatus;
      }
      catch(e){
          console.log('checkSession catched e:: ', e);
          throw e;
      }
    };

    const fetchUser = async () => {
      try {
        grpcCallStatus = checkSession(setUserIsLoggedIn);
      }
      catch(e) {
        setUserIsLoggedOut();
      }

    }
    fetchUser();    
    
    return () => {
      console.log('userContext useEffect getSession() grpcCall status::: ', grpcCallStatus);
      if (grpcCallStatus) {
        grpcCallStatus.cancel();
      }
    };
  }, [setUserIsLoggedIn, setUserIsLoggedOut, grpcClients.authClient, handleGrpcRequest]);


  const value = useMemo(
    () => ({ isLoading, isAuthenticated, getUser, setUserIsLoggedIn, setUserIsLoggedOut, handleLogout }),
    [isLoading, isAuthenticated, getUser, setUserIsLoggedIn, setUserIsLoggedOut, handleLogout ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
