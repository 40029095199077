// source: protofiles/markers.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.markers.Empty', null, global);
goog.exportSymbol('proto.markers.GetMyCompletedSolicitationsRequest', null, global);
goog.exportSymbol('proto.markers.GetMyCompletedSolicitationsResponse', null, global);
goog.exportSymbol('proto.markers.MarkerRequest', null, global);
goog.exportSymbol('proto.markers.MarkerResponse', null, global);
goog.exportSymbol('proto.markers.MarkerWithOffer', null, global);
goog.exportSymbol('proto.markers.MarkersResponse', null, global);
goog.exportSymbol('proto.markers.MarkersWithOffersRequest', null, global);
goog.exportSymbol('proto.markers.MarkersWithOffersResponse', null, global);
goog.exportSymbol('proto.markers.Offer', null, global);
goog.exportSymbol('proto.markers.VehicleResponse', null, global);
goog.exportSymbol('proto.markers.locationObj', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.markers.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.Empty.displayName = 'proto.markers.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.locationObj = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.markers.locationObj, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.locationObj.displayName = 'proto.markers.locationObj';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.MarkerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.markers.MarkerRequest.repeatedFields_, null);
};
goog.inherits(proto.markers.MarkerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.MarkerRequest.displayName = 'proto.markers.MarkerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.VehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.markers.VehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.VehicleResponse.displayName = 'proto.markers.VehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.MarkerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.markers.MarkerResponse.repeatedFields_, null);
};
goog.inherits(proto.markers.MarkerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.MarkerResponse.displayName = 'proto.markers.MarkerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.MarkersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.markers.MarkersResponse.repeatedFields_, null);
};
goog.inherits(proto.markers.MarkersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.MarkersResponse.displayName = 'proto.markers.MarkersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.MarkersWithOffersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.markers.MarkersWithOffersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.MarkersWithOffersRequest.displayName = 'proto.markers.MarkersWithOffersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.Offer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.markers.Offer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.Offer.displayName = 'proto.markers.Offer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.MarkerWithOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.markers.MarkerWithOffer.repeatedFields_, null);
};
goog.inherits(proto.markers.MarkerWithOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.MarkerWithOffer.displayName = 'proto.markers.MarkerWithOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.MarkersWithOffersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.markers.MarkersWithOffersResponse.repeatedFields_, null);
};
goog.inherits(proto.markers.MarkersWithOffersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.MarkersWithOffersResponse.displayName = 'proto.markers.MarkersWithOffersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.GetMyCompletedSolicitationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.markers.GetMyCompletedSolicitationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.GetMyCompletedSolicitationsRequest.displayName = 'proto.markers.GetMyCompletedSolicitationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.markers.GetMyCompletedSolicitationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.markers.GetMyCompletedSolicitationsResponse.repeatedFields_, null);
};
goog.inherits(proto.markers.GetMyCompletedSolicitationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.markers.GetMyCompletedSolicitationsResponse.displayName = 'proto.markers.GetMyCompletedSolicitationsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.Empty}
 */
proto.markers.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.Empty;
  return proto.markers.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.Empty}
 */
proto.markers.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.locationObj.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.locationObj.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.locationObj} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.locationObj.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.locationObj}
 */
proto.markers.locationObj.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.locationObj;
  return proto.markers.locationObj.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.locationObj} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.locationObj}
 */
proto.markers.locationObj.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLng(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.locationObj.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.locationObj.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.locationObj} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.locationObj.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.markers.locationObj.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.locationObj} returns this
 */
proto.markers.locationObj.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float lat = 2;
 * @return {number}
 */
proto.markers.locationObj.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.markers.locationObj} returns this
 */
proto.markers.locationObj.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float lng = 3;
 * @return {number}
 */
proto.markers.locationObj.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.markers.locationObj} returns this
 */
proto.markers.locationObj.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.markers.MarkerRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.MarkerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.MarkerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.MarkerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    arrivaldate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    arrivaltime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destination: (f = msg.getDestination()) && proto.markers.locationObj.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.markers.locationObj.toObject(includeInstance, f),
    passangersno: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stopsList: jspb.Message.toObjectList(msg.getStopsList(),
    proto.markers.locationObj.toObject, includeInstance),
    hascar: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    carid: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.MarkerRequest}
 */
proto.markers.MarkerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.MarkerRequest;
  return proto.markers.MarkerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.MarkerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.MarkerRequest}
 */
proto.markers.MarkerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivaldate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivaltime(value);
      break;
    case 4:
      var value = new proto.markers.locationObj;
      reader.readMessage(value,proto.markers.locationObj.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 5:
      var value = new proto.markers.locationObj;
      reader.readMessage(value,proto.markers.locationObj.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassangersno(value);
      break;
    case 7:
      var value = new proto.markers.locationObj;
      reader.readMessage(value,proto.markers.locationObj.deserializeBinaryFromReader);
      msg.addStops(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHascar(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCarid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.MarkerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.MarkerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.MarkerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArrivaldate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getArrivaltime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.markers.locationObj.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.markers.locationObj.serializeBinaryToWriter
    );
  }
  f = message.getPassangersno();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.markers.locationObj.serializeBinaryToWriter
    );
  }
  f = message.getHascar();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCarid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.markers.MarkerRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerRequest} returns this
 */
proto.markers.MarkerRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string arrivalDate = 2;
 * @return {string}
 */
proto.markers.MarkerRequest.prototype.getArrivaldate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerRequest} returns this
 */
proto.markers.MarkerRequest.prototype.setArrivaldate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string arrivalTime = 3;
 * @return {string}
 */
proto.markers.MarkerRequest.prototype.getArrivaltime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerRequest} returns this
 */
proto.markers.MarkerRequest.prototype.setArrivaltime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional locationObj destination = 4;
 * @return {?proto.markers.locationObj}
 */
proto.markers.MarkerRequest.prototype.getDestination = function() {
  return /** @type{?proto.markers.locationObj} */ (
    jspb.Message.getWrapperField(this, proto.markers.locationObj, 4));
};


/**
 * @param {?proto.markers.locationObj|undefined} value
 * @return {!proto.markers.MarkerRequest} returns this
*/
proto.markers.MarkerRequest.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.markers.MarkerRequest} returns this
 */
proto.markers.MarkerRequest.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.markers.MarkerRequest.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional locationObj location = 5;
 * @return {?proto.markers.locationObj}
 */
proto.markers.MarkerRequest.prototype.getLocation = function() {
  return /** @type{?proto.markers.locationObj} */ (
    jspb.Message.getWrapperField(this, proto.markers.locationObj, 5));
};


/**
 * @param {?proto.markers.locationObj|undefined} value
 * @return {!proto.markers.MarkerRequest} returns this
*/
proto.markers.MarkerRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.markers.MarkerRequest} returns this
 */
proto.markers.MarkerRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.markers.MarkerRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string passangersNo = 6;
 * @return {string}
 */
proto.markers.MarkerRequest.prototype.getPassangersno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerRequest} returns this
 */
proto.markers.MarkerRequest.prototype.setPassangersno = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated locationObj stops = 7;
 * @return {!Array<!proto.markers.locationObj>}
 */
proto.markers.MarkerRequest.prototype.getStopsList = function() {
  return /** @type{!Array<!proto.markers.locationObj>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.markers.locationObj, 7));
};


/**
 * @param {!Array<!proto.markers.locationObj>} value
 * @return {!proto.markers.MarkerRequest} returns this
*/
proto.markers.MarkerRequest.prototype.setStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.markers.locationObj=} opt_value
 * @param {number=} opt_index
 * @return {!proto.markers.locationObj}
 */
proto.markers.MarkerRequest.prototype.addStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.markers.locationObj, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.markers.MarkerRequest} returns this
 */
proto.markers.MarkerRequest.prototype.clearStopsList = function() {
  return this.setStopsList([]);
};


/**
 * optional bool hasCar = 8;
 * @return {boolean}
 */
proto.markers.MarkerRequest.prototype.getHascar = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.markers.MarkerRequest} returns this
 */
proto.markers.MarkerRequest.prototype.setHascar = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 carId = 9;
 * @return {number}
 */
proto.markers.MarkerRequest.prototype.getCarid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.MarkerRequest} returns this
 */
proto.markers.MarkerRequest.prototype.setCarid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.VehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.VehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.VehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.VehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    plate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    seatsno: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.VehicleResponse}
 */
proto.markers.VehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.VehicleResponse;
  return proto.markers.VehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.VehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.VehicleResponse}
 */
proto.markers.VehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeatsno(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.VehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.VehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.VehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.VehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSeatsno();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.markers.VehicleResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.VehicleResponse} returns this
 */
proto.markers.VehicleResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.markers.VehicleResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.VehicleResponse} returns this
 */
proto.markers.VehicleResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.markers.VehicleResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.VehicleResponse} returns this
 */
proto.markers.VehicleResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.markers.VehicleResponse.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.VehicleResponse} returns this
 */
proto.markers.VehicleResponse.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string plate = 5;
 * @return {string}
 */
proto.markers.VehicleResponse.prototype.getPlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.VehicleResponse} returns this
 */
proto.markers.VehicleResponse.prototype.setPlate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 seatsNo = 6;
 * @return {number}
 */
proto.markers.VehicleResponse.prototype.getSeatsno = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.VehicleResponse} returns this
 */
proto.markers.VehicleResponse.prototype.setSeatsno = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.markers.MarkerResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.MarkerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.MarkerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.MarkerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    arrivaldatetime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.markers.locationObj.toObject(includeInstance, f),
    destination: (f = msg.getDestination()) && proto.markers.locationObj.toObject(includeInstance, f),
    stopsList: jspb.Message.toObjectList(msg.getStopsList(),
    proto.markers.locationObj.toObject, includeInstance),
    hascar: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    vehicles: (f = msg.getVehicles()) && proto.markers.VehicleResponse.toObject(includeInstance, f),
    passangersno: jspb.Message.getFieldWithDefault(msg, 8, 0),
    id: jspb.Message.getFieldWithDefault(msg, 9, 0),
    type: jspb.Message.getFieldWithDefault(msg, 10, ""),
    username: jspb.Message.getFieldWithDefault(msg, 11, ""),
    useremail: jspb.Message.getFieldWithDefault(msg, 12, ""),
    userpic: jspb.Message.getFieldWithDefault(msg, 13, ""),
    status: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.MarkerResponse}
 */
proto.markers.MarkerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.MarkerResponse;
  return proto.markers.MarkerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.MarkerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.MarkerResponse}
 */
proto.markers.MarkerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivaldatetime(value);
      break;
    case 3:
      var value = new proto.markers.locationObj;
      reader.readMessage(value,proto.markers.locationObj.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 4:
      var value = new proto.markers.locationObj;
      reader.readMessage(value,proto.markers.locationObj.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 5:
      var value = new proto.markers.locationObj;
      reader.readMessage(value,proto.markers.locationObj.deserializeBinaryFromReader);
      msg.addStops(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHascar(value);
      break;
    case 7:
      var value = new proto.markers.VehicleResponse;
      reader.readMessage(value,proto.markers.VehicleResponse.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPassangersno(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseremail(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserpic(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.MarkerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.MarkerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.MarkerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArrivaldatetime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.markers.locationObj.serializeBinaryToWriter
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.markers.locationObj.serializeBinaryToWriter
    );
  }
  f = message.getStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.markers.locationObj.serializeBinaryToWriter
    );
  }
  f = message.getHascar();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.markers.VehicleResponse.serializeBinaryToWriter
    );
  }
  f = message.getPassangersno();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUseremail();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUserpic();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.markers.MarkerResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string arrivalDateTime = 2;
 * @return {string}
 */
proto.markers.MarkerResponse.prototype.getArrivaldatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setArrivaldatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional locationObj location = 3;
 * @return {?proto.markers.locationObj}
 */
proto.markers.MarkerResponse.prototype.getLocation = function() {
  return /** @type{?proto.markers.locationObj} */ (
    jspb.Message.getWrapperField(this, proto.markers.locationObj, 3));
};


/**
 * @param {?proto.markers.locationObj|undefined} value
 * @return {!proto.markers.MarkerResponse} returns this
*/
proto.markers.MarkerResponse.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.markers.MarkerResponse.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional locationObj destination = 4;
 * @return {?proto.markers.locationObj}
 */
proto.markers.MarkerResponse.prototype.getDestination = function() {
  return /** @type{?proto.markers.locationObj} */ (
    jspb.Message.getWrapperField(this, proto.markers.locationObj, 4));
};


/**
 * @param {?proto.markers.locationObj|undefined} value
 * @return {!proto.markers.MarkerResponse} returns this
*/
proto.markers.MarkerResponse.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.markers.MarkerResponse.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated locationObj stops = 5;
 * @return {!Array<!proto.markers.locationObj>}
 */
proto.markers.MarkerResponse.prototype.getStopsList = function() {
  return /** @type{!Array<!proto.markers.locationObj>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.markers.locationObj, 5));
};


/**
 * @param {!Array<!proto.markers.locationObj>} value
 * @return {!proto.markers.MarkerResponse} returns this
*/
proto.markers.MarkerResponse.prototype.setStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.markers.locationObj=} opt_value
 * @param {number=} opt_index
 * @return {!proto.markers.locationObj}
 */
proto.markers.MarkerResponse.prototype.addStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.markers.locationObj, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.clearStopsList = function() {
  return this.setStopsList([]);
};


/**
 * optional bool hasCar = 6;
 * @return {boolean}
 */
proto.markers.MarkerResponse.prototype.getHascar = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setHascar = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional VehicleResponse vehicles = 7;
 * @return {?proto.markers.VehicleResponse}
 */
proto.markers.MarkerResponse.prototype.getVehicles = function() {
  return /** @type{?proto.markers.VehicleResponse} */ (
    jspb.Message.getWrapperField(this, proto.markers.VehicleResponse, 7));
};


/**
 * @param {?proto.markers.VehicleResponse|undefined} value
 * @return {!proto.markers.MarkerResponse} returns this
*/
proto.markers.MarkerResponse.prototype.setVehicles = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.markers.MarkerResponse.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 passangersNo = 8;
 * @return {number}
 */
proto.markers.MarkerResponse.prototype.getPassangersno = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setPassangersno = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 id = 9;
 * @return {number}
 */
proto.markers.MarkerResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string type = 10;
 * @return {string}
 */
proto.markers.MarkerResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string userName = 11;
 * @return {string}
 */
proto.markers.MarkerResponse.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string userEmail = 12;
 * @return {string}
 */
proto.markers.MarkerResponse.prototype.getUseremail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setUseremail = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string userPic = 13;
 * @return {string}
 */
proto.markers.MarkerResponse.prototype.getUserpic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setUserpic = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string status = 14;
 * @return {string}
 */
proto.markers.MarkerResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerResponse} returns this
 */
proto.markers.MarkerResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.markers.MarkersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.MarkersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.MarkersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.MarkersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    markersList: jspb.Message.toObjectList(msg.getMarkersList(),
    proto.markers.MarkerResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.MarkersResponse}
 */
proto.markers.MarkersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.MarkersResponse;
  return proto.markers.MarkersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.MarkersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.MarkersResponse}
 */
proto.markers.MarkersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.markers.MarkerResponse;
      reader.readMessage(value,proto.markers.MarkerResponse.deserializeBinaryFromReader);
      msg.addMarkers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.MarkersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.MarkersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.MarkersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarkersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.markers.MarkerResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarkerResponse markers = 1;
 * @return {!Array<!proto.markers.MarkerResponse>}
 */
proto.markers.MarkersResponse.prototype.getMarkersList = function() {
  return /** @type{!Array<!proto.markers.MarkerResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.markers.MarkerResponse, 1));
};


/**
 * @param {!Array<!proto.markers.MarkerResponse>} value
 * @return {!proto.markers.MarkersResponse} returns this
*/
proto.markers.MarkersResponse.prototype.setMarkersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.markers.MarkerResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.markers.MarkerResponse}
 */
proto.markers.MarkersResponse.prototype.addMarkers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.markers.MarkerResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.markers.MarkersResponse} returns this
 */
proto.markers.MarkersResponse.prototype.clearMarkersList = function() {
  return this.setMarkersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.MarkersWithOffersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.MarkersWithOffersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.MarkersWithOffersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkersWithOffersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.MarkersWithOffersRequest}
 */
proto.markers.MarkersWithOffersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.MarkersWithOffersRequest;
  return proto.markers.MarkersWithOffersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.MarkersWithOffersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.MarkersWithOffersRequest}
 */
proto.markers.MarkersWithOffersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.MarkersWithOffersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.MarkersWithOffersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.MarkersWithOffersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkersWithOffersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.markers.MarkersWithOffersRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkersWithOffersRequest} returns this
 */
proto.markers.MarkersWithOffersRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.Offer.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.Offer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.Offer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.Offer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    markerid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    carid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    vehicle: (f = msg.getVehicle()) && proto.markers.VehicleResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.Offer}
 */
proto.markers.Offer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.Offer;
  return proto.markers.Offer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.Offer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.Offer}
 */
proto.markers.Offer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarkerid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCarid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 12:
      var value = new proto.markers.VehicleResponse;
      reader.readMessage(value,proto.markers.VehicleResponse.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.Offer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.Offer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.Offer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.Offer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMarkerid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCarid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.markers.VehicleResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.markers.Offer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.Offer} returns this
 */
proto.markers.Offer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.markers.Offer.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.Offer} returns this
 */
proto.markers.Offer.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string markerId = 3;
 * @return {string}
 */
proto.markers.Offer.prototype.getMarkerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.Offer} returns this
 */
proto.markers.Offer.prototype.setMarkerid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 carId = 4;
 * @return {number}
 */
proto.markers.Offer.prototype.getCarid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.Offer} returns this
 */
proto.markers.Offer.prototype.setCarid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 amount = 5;
 * @return {number}
 */
proto.markers.Offer.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.Offer} returns this
 */
proto.markers.Offer.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.markers.Offer.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.Offer} returns this
 */
proto.markers.Offer.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional VehicleResponse vehicle = 12;
 * @return {?proto.markers.VehicleResponse}
 */
proto.markers.Offer.prototype.getVehicle = function() {
  return /** @type{?proto.markers.VehicleResponse} */ (
    jspb.Message.getWrapperField(this, proto.markers.VehicleResponse, 12));
};


/**
 * @param {?proto.markers.VehicleResponse|undefined} value
 * @return {!proto.markers.Offer} returns this
*/
proto.markers.Offer.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.markers.Offer} returns this
 */
proto.markers.Offer.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.markers.Offer.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.markers.MarkerWithOffer.repeatedFields_ = [7,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.MarkerWithOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.MarkerWithOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.MarkerWithOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkerWithOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    passangersno: jspb.Message.getFieldWithDefault(msg, 4, 0),
    location: (f = msg.getLocation()) && proto.markers.locationObj.toObject(includeInstance, f),
    destination: (f = msg.getDestination()) && proto.markers.locationObj.toObject(includeInstance, f),
    stopsList: jspb.Message.toObjectList(msg.getStopsList(),
    proto.markers.locationObj.toObject, includeInstance),
    arrivaldatetime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 9, ""),
    updatedat: jspb.Message.getFieldWithDefault(msg, 10, ""),
    offersList: jspb.Message.toObjectList(msg.getOffersList(),
    proto.markers.Offer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.MarkerWithOffer}
 */
proto.markers.MarkerWithOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.MarkerWithOffer;
  return proto.markers.MarkerWithOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.MarkerWithOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.MarkerWithOffer}
 */
proto.markers.MarkerWithOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPassangersno(value);
      break;
    case 5:
      var value = new proto.markers.locationObj;
      reader.readMessage(value,proto.markers.locationObj.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 6:
      var value = new proto.markers.locationObj;
      reader.readMessage(value,proto.markers.locationObj.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 7:
      var value = new proto.markers.locationObj;
      reader.readMessage(value,proto.markers.locationObj.deserializeBinaryFromReader);
      msg.addStops(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivaldatetime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedat(value);
      break;
    case 11:
      var value = new proto.markers.Offer;
      reader.readMessage(value,proto.markers.Offer.deserializeBinaryFromReader);
      msg.addOffers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.MarkerWithOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.MarkerWithOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.MarkerWithOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkerWithOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassangersno();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.markers.locationObj.serializeBinaryToWriter
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.markers.locationObj.serializeBinaryToWriter
    );
  }
  f = message.getStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.markers.locationObj.serializeBinaryToWriter
    );
  }
  f = message.getArrivaldatetime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUpdatedat();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.markers.Offer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.markers.MarkerWithOffer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.markers.MarkerWithOffer.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.markers.MarkerWithOffer.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 passangersNo = 4;
 * @return {number}
 */
proto.markers.MarkerWithOffer.prototype.getPassangersno = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.setPassangersno = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional locationObj location = 5;
 * @return {?proto.markers.locationObj}
 */
proto.markers.MarkerWithOffer.prototype.getLocation = function() {
  return /** @type{?proto.markers.locationObj} */ (
    jspb.Message.getWrapperField(this, proto.markers.locationObj, 5));
};


/**
 * @param {?proto.markers.locationObj|undefined} value
 * @return {!proto.markers.MarkerWithOffer} returns this
*/
proto.markers.MarkerWithOffer.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.markers.MarkerWithOffer.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional locationObj destination = 6;
 * @return {?proto.markers.locationObj}
 */
proto.markers.MarkerWithOffer.prototype.getDestination = function() {
  return /** @type{?proto.markers.locationObj} */ (
    jspb.Message.getWrapperField(this, proto.markers.locationObj, 6));
};


/**
 * @param {?proto.markers.locationObj|undefined} value
 * @return {!proto.markers.MarkerWithOffer} returns this
*/
proto.markers.MarkerWithOffer.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.markers.MarkerWithOffer.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated locationObj stops = 7;
 * @return {!Array<!proto.markers.locationObj>}
 */
proto.markers.MarkerWithOffer.prototype.getStopsList = function() {
  return /** @type{!Array<!proto.markers.locationObj>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.markers.locationObj, 7));
};


/**
 * @param {!Array<!proto.markers.locationObj>} value
 * @return {!proto.markers.MarkerWithOffer} returns this
*/
proto.markers.MarkerWithOffer.prototype.setStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.markers.locationObj=} opt_value
 * @param {number=} opt_index
 * @return {!proto.markers.locationObj}
 */
proto.markers.MarkerWithOffer.prototype.addStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.markers.locationObj, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.clearStopsList = function() {
  return this.setStopsList([]);
};


/**
 * optional string arrivalDateTime = 8;
 * @return {string}
 */
proto.markers.MarkerWithOffer.prototype.getArrivaldatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.setArrivaldatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string createdAt = 9;
 * @return {string}
 */
proto.markers.MarkerWithOffer.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string updatedAt = 10;
 * @return {string}
 */
proto.markers.MarkerWithOffer.prototype.getUpdatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated Offer offers = 11;
 * @return {!Array<!proto.markers.Offer>}
 */
proto.markers.MarkerWithOffer.prototype.getOffersList = function() {
  return /** @type{!Array<!proto.markers.Offer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.markers.Offer, 11));
};


/**
 * @param {!Array<!proto.markers.Offer>} value
 * @return {!proto.markers.MarkerWithOffer} returns this
*/
proto.markers.MarkerWithOffer.prototype.setOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.markers.Offer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.markers.Offer}
 */
proto.markers.MarkerWithOffer.prototype.addOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.markers.Offer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.markers.MarkerWithOffer} returns this
 */
proto.markers.MarkerWithOffer.prototype.clearOffersList = function() {
  return this.setOffersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.markers.MarkersWithOffersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.MarkersWithOffersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.MarkersWithOffersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.MarkersWithOffersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkersWithOffersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    markersList: jspb.Message.toObjectList(msg.getMarkersList(),
    proto.markers.MarkerWithOffer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.MarkersWithOffersResponse}
 */
proto.markers.MarkersWithOffersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.MarkersWithOffersResponse;
  return proto.markers.MarkersWithOffersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.MarkersWithOffersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.MarkersWithOffersResponse}
 */
proto.markers.MarkersWithOffersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.markers.MarkerWithOffer;
      reader.readMessage(value,proto.markers.MarkerWithOffer.deserializeBinaryFromReader);
      msg.addMarkers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.MarkersWithOffersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.MarkersWithOffersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.MarkersWithOffersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.MarkersWithOffersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarkersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.markers.MarkerWithOffer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarkerWithOffer markers = 1;
 * @return {!Array<!proto.markers.MarkerWithOffer>}
 */
proto.markers.MarkersWithOffersResponse.prototype.getMarkersList = function() {
  return /** @type{!Array<!proto.markers.MarkerWithOffer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.markers.MarkerWithOffer, 1));
};


/**
 * @param {!Array<!proto.markers.MarkerWithOffer>} value
 * @return {!proto.markers.MarkersWithOffersResponse} returns this
*/
proto.markers.MarkersWithOffersResponse.prototype.setMarkersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.markers.MarkerWithOffer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.markers.MarkerWithOffer}
 */
proto.markers.MarkersWithOffersResponse.prototype.addMarkers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.markers.MarkerWithOffer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.markers.MarkersWithOffersResponse} returns this
 */
proto.markers.MarkersWithOffersResponse.prototype.clearMarkersList = function() {
  return this.setMarkersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.GetMyCompletedSolicitationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.GetMyCompletedSolicitationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.GetMyCompletedSolicitationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.GetMyCompletedSolicitationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.GetMyCompletedSolicitationsRequest}
 */
proto.markers.GetMyCompletedSolicitationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.GetMyCompletedSolicitationsRequest;
  return proto.markers.GetMyCompletedSolicitationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.GetMyCompletedSolicitationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.GetMyCompletedSolicitationsRequest}
 */
proto.markers.GetMyCompletedSolicitationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.GetMyCompletedSolicitationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.GetMyCompletedSolicitationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.GetMyCompletedSolicitationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.GetMyCompletedSolicitationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.markers.GetMyCompletedSolicitationsRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.markers.GetMyCompletedSolicitationsRequest} returns this
 */
proto.markers.GetMyCompletedSolicitationsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.markers.GetMyCompletedSolicitationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.markers.GetMyCompletedSolicitationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.markers.GetMyCompletedSolicitationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.markers.GetMyCompletedSolicitationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.GetMyCompletedSolicitationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    markersList: jspb.Message.toObjectList(msg.getMarkersList(),
    proto.markers.MarkerWithOffer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.markers.GetMyCompletedSolicitationsResponse}
 */
proto.markers.GetMyCompletedSolicitationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.markers.GetMyCompletedSolicitationsResponse;
  return proto.markers.GetMyCompletedSolicitationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.markers.GetMyCompletedSolicitationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.markers.GetMyCompletedSolicitationsResponse}
 */
proto.markers.GetMyCompletedSolicitationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.markers.MarkerWithOffer;
      reader.readMessage(value,proto.markers.MarkerWithOffer.deserializeBinaryFromReader);
      msg.addMarkers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.markers.GetMyCompletedSolicitationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.markers.GetMyCompletedSolicitationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.markers.GetMyCompletedSolicitationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.markers.GetMyCompletedSolicitationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarkersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.markers.MarkerWithOffer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarkerWithOffer markers = 1;
 * @return {!Array<!proto.markers.MarkerWithOffer>}
 */
proto.markers.GetMyCompletedSolicitationsResponse.prototype.getMarkersList = function() {
  return /** @type{!Array<!proto.markers.MarkerWithOffer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.markers.MarkerWithOffer, 1));
};


/**
 * @param {!Array<!proto.markers.MarkerWithOffer>} value
 * @return {!proto.markers.GetMyCompletedSolicitationsResponse} returns this
*/
proto.markers.GetMyCompletedSolicitationsResponse.prototype.setMarkersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.markers.MarkerWithOffer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.markers.MarkerWithOffer}
 */
proto.markers.GetMyCompletedSolicitationsResponse.prototype.addMarkers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.markers.MarkerWithOffer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.markers.GetMyCompletedSolicitationsResponse} returns this
 */
proto.markers.GetMyCompletedSolicitationsResponse.prototype.clearMarkersList = function() {
  return this.setMarkersList([]);
};


goog.object.extend(exports, proto.markers);
