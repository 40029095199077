import React, { useCallback, useEffect, useState }  from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import {useReceivedOffersContext} from '../context/receivedOffersContext';
import { useNavigate } from "react-router-dom";
import {useModalProvider} from '../context/ModalProviderContext';
import VehicleDetails from './VehicleDetails';

const OffCanvas = ({ show, handleClose, name, payload, ...props }) => {
    const [content, setContent] = useState();
    const {getContextMarkersWithOffers, setContextMarkersWithOffers, setNewReceivedOfferStatus} = useReceivedOffersContext();
    const navigate = useNavigate();
    const {setModalConfig, showModal} = useModalProvider();

    const markOffersAsOld = useCallback((mid) => {
      const markersAndOffers = [...getContextMarkersWithOffers()];
      console.log('mmarkOffersAsOld() arkersAndOffers:::: ', markersAndOffers);
      const markerIndex = markersAndOffers.findIndex(marker => marker.id === mid);
      if(markerIndex > -1) {
        console.log('we have marker index');
        const marker = markersAndOffers[markerIndex];

        const newOfferList = marker.offersList.map(offer => {
          const {type, ...offerWithoutTypeAttribute} = offer;
          console.log(`offerid, type::: ${offer.id} , ${type}`);
          return offerWithoutTypeAttribute;
        });
        marker.offersList = newOfferList;
        markersAndOffers[markerIndex] = {...marker};
        setContextMarkersWithOffers(markersAndOffers);
      }
    }, 
    [getContextMarkersWithOffers, setContextMarkersWithOffers]);

    const handleWiningOffer = useCallback((markerId, offerId) => {
      console.log('markerId::: ', markerId);
      console.log('offerId::: ', offerId);
      let content;
      if (markerId && offerId) {
        const markers = getContextMarkersWithOffers();
        const marker = markers.find(m => m.id === markerId);
        console.log('handleWiningOffer() marker::: ', marker);
        if (marker) { 
          const offer = marker.offersList.find(o => o.id === offerId);
          if (offer) {
            const handlePayment = () => {
              console.log('handleing payment::: ');
              handleClose();
              return navigate(`/payment?mid=${markerId}&oid=${offerId}`);
            }
            const {destination, location, passangersno, stopsList, type, updatedat} = marker;
            setModalConfig( 
              { 
                content: <>
                  <div>
                    <span>Type: {type}</span>
                    <br/>
                    <span>Passengers no. :{passangersno}</span>
                    <br/>
                    <span>Location: {location?.address || `${location.lat} - ${location.lng}`}</span>
                    <br/>
                    <span>Destinations: {destination?.address || `${destination.lat} - ${destination.lng}`}</span>
                    <br/>
                    <span>Stops: {stopsList.length}</span>
                    <br />
                    <br />
                    <span>Offer Amount: {offer?.amount}</span>
                  </div>

                </>,
                header: "Choose winning offer",
                handleSave: handlePayment
              } 
            );
            showModal();
          }
        } 
      }

    },[setModalConfig, showModal, getContextMarkersWithOffers, navigate, handleClose]);

    const processPayload = useCallback((payload) => {
      let accordionContent;
      if (payload.length) {
        let anyNewOffer = false;
        accordionContent = payload.map(markerWithOffers => {
          console.log('processPayload markerWithOffers::: ', markerWithOffers);

          const eventKey = markerWithOffers.id;
          // const markerLocation = markerWithOffers.location;
          // const {lat: markerLat, lng: markerLng} = markerLocation;

          const key = eventKey;
          const headerContent = <span>{markerWithOffers.location.address} - {markerWithOffers.destination.address}</span>;
          const offerList = markerWithOffers.offersList;
          let offersListContent = <div>No offers for this marker</div>;
  
          let shouldHighlight = false;
  
          if (offerList.length > 0) {
            offersListContent = markerWithOffers.offersList.map((offer,i) => {
              const {id, userid, carid, amount, /* markerid, */ type, status, vehicle } = offer; 
              
              if (type === "new") {
                shouldHighlight = true;
                anyNewOffer = true;
              }

              if (type === "winner") {
                shouldHighlight = true;
              }
  
              return (
                <ListGroup.Item key={ `o${id}` } variant={shouldHighlight ? "secondary" : ""} as="li" >
                  <span># {userid} Car: {carid} </span>
                  {      
                    vehicle && 
                    <VehicleDetails 
                      model={ vehicle.model }
                      type={ vehicle.type }
                      plate={ vehicle.plate } 
                      seatsNo={ vehicle.seatsno }
                    />
                  }                  
                  <span>Amount: {amount} </span>
                  <br/>
                  {name === "Sent Offers" && <span> Status: {`${ status }`} </span>  }                  
                  <br/>
                  { name === 'Received Offers' && <Button onClick={() => handleWiningOffer(markerWithOffers.id, id)} > Choose as winner</Button> }
                </ListGroup.Item>
              );

            });
          }

      
  
          return <Accordion.Item eventKey={eventKey} key={`markerAccordion${key}`} >
                  <Accordion.Header className={shouldHighlight && 'highlightedMarkerWithOffer'} onClick={ () => navigate( `/drive?m=${markerWithOffers.id}` ) }>
                    {headerContent} 
                    <Badge bg="primary" pill>
                      {offersListContent.length || 0}
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Body onExited={ () => { markOffersAsOld(markerWithOffers.id) } } >
                    <ListGroup as="ol" numbered key={`markerOffers${markerWithOffers.id}`}>
                      {offersListContent}
                      </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
        });
        setNewReceivedOfferStatus(anyNewOffer);
      }
      return accordionContent;
    }, 
    [markOffersAsOld, navigate, setNewReceivedOfferStatus, handleWiningOffer, name]);

    

    useEffect(() => {
      const newContent = processPayload(payload) ;
      setContent(newContent);
    }, [payload, processPayload]);

    return(
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Accordion>
            { content }
          </Accordion>
        </Offcanvas.Body>
      </Offcanvas>
    );
  };
export default React.memo(OffCanvas);
