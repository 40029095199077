import config from '../config';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { GoogleLogin } from '@react-oauth/google';
import { 
    FacebookLoginRequest,
    GoogleLoginRequest, 
} from '../protofiles/auth_pb'; // gRPC message classes
import {useUser} from '../context/userContext';
import { useGrpc } from '../context/grpcContext';
import { decodeToken } from "react-jwt";

const LoginPage = () => {
    const { grpcClients } = useGrpc();
    const { setUserIsLoggedIn } = useUser();

    function handleLoginFacebookSuccess(data) {
        try {
            const { accessToken } = data;
            console.log('handleSuccessFacebook accessToken:: ', accessToken);
            const request = new FacebookLoginRequest();
            request.setToken(accessToken);    

            const facebookLoginCb = (err, res) => {
                if (err) {
                    console.error('Facebook Login failed:: ', err);
                    return;
                }

                const userData = res.toObject();
                console.log('Facebook Login userData:: ', userData);
                localStorage.setItem('sessionid', userData.sessionid);
                setUserIsLoggedIn(userData);
            };

            grpcClients.authClient.facebookLogin(request, {}, facebookLoginCb);
        }
        catch(e) {
            console.log('handleLoginFacebookSuccess catched e:: ', e);
            throw e;
        }
    }

    const handleLoginGoogleSuccess = (credential) => {
        try {
            console.log('decodeToken(credentialResponse.credential):: ', decodeToken(credential));
            const request = new GoogleLoginRequest();
            request.setToken(credential);

            const googleLoginCb = (err, res) => {
                if (err) {
                    console.error('Google Login failed:: ', err);
                    return;
                }
                const userData = res.toObject();
                console.log('Google Login userData:: ', userData);
                localStorage.setItem('sessionid', userData.sessionid);
                setUserIsLoggedIn(userData);
            };

            grpcClients.authClient.googleLogin(request, {}, googleLoginCb);
        }
        catch(e){
            console.log('handleLoginGoogleSuccess catched e:: ', e);
            throw e;
        }

    };

    return (
        <div>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    // console.log('credentialResponse:: ', credentialResponse, Object.keys(credentialResponse));
                    const {credential, clientId, select_by} = credentialResponse;
                    handleLoginGoogleSuccess(credential);
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
            <br/>

            <LoginSocialFacebook
                isOnlyGetToken
                appId={config.fClientId}
                // onLoginStart={onLoginStart}
                onResolve={
                    ({ provider, data }) => {
                        console.log(provider, data);
                        handleLoginFacebookSuccess(data);
                    }
                }
                onReject={(err) => {
                    console.log(err)
                }}
            >
                <button>facebook</button>
            </LoginSocialFacebook>
        </div>
    );
};

export default LoginPage;
