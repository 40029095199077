import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from './config';
import { AuthServiceClient } from './protofiles/auth_grpc_web_pb';
import { MarkersServiceClient } from './protofiles/markers_grpc_web_pb';
import { OffersServiceClient } from './protofiles/offers_grpc_web_pb';
import { VehiclesServiceClient } from './protofiles/vehicles_grpc_web_pb';
import GrpcProvider from './context/grpcContext';
import UserProvider from './context/userContext';
import SocketIoClientProvider from './context/socketIoClientContext';
import ReceivedOffersProvider from './context/receivedOffersContext';
import { BrowserRouter } from "react-router-dom";
import ModalProvider from './context/ModalProviderContext';
const authClient = new AuthServiceClient(config.gRpcServer, null, config.gRpcOptions);
const markersClient = new MarkersServiceClient(config.gRpcServer, null, config.gRpcOptions);
const offersClient = new OffersServiceClient(config.gRpcServer, null, config.gRpcOptions);
const vehiclesClient = new VehiclesServiceClient(config.gRpcServer, null, config.gRpcOptions);

const grpcClients = {
  authClient,
  markersClient,
  offersClient,
  vehiclesClient
};
console.log('grpcClients::: ', grpcClients);

const root = ReactDOM.createRoot(document.getElementById('root'));

console.log('rendering....');

root.render(
  <React.StrictMode>
    <GrpcProvider grpcClients={grpcClients}>
      <UserProvider >
        <ReceivedOffersProvider>
          <SocketIoClientProvider>
            <ModalProvider>
              <GoogleOAuthProvider clientId={config.gClientId}>
                <BrowserRouter>

                    <App />

                </BrowserRouter>
              </GoogleOAuthProvider>      
            </ModalProvider>
          </SocketIoClientProvider>
        </ReceivedOffersProvider>

      </UserProvider>      
    </GrpcProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
