module.exports = {
    gRpcServer: process.env.REACT_APP_GRPC_SERVER,
    gRpcOptions: {
      keepCase: true,
      longs: String,
      enums: String,
      defaults: true,
      oneofs: true,
    },
    gClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    fClientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID ,
    maps: {
      gMapsApiKey : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      gMapsLibraries: ['places'],
      redPinIcon: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
      greenPinIcon: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
      yellowPinIcon: "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
      mapCenteredToLocation: {  //craiova
        lat: 44.3302, // default latitude
        lng: 23.7949, // default longitude
      },
      // mapCenteredToLocation: {
      //   lat: 54.3302, // default latitude
      //   lng: 33.7949, // default longitude
      // }      
    },
    vehicles: {
      types: {
        0: 'moto',
        1: 'car',
        2: 'van',
        3: 'minibus',
        4: 'bus',
        5: 'truck',
        6: 'plane'
      }
    }

};

