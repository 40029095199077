import React, { useEffect, useState } from 'react';
import { useUser } from './context/userContext';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import MapPage from './pages/MapPage';
import NewMarkerPage from './pages/NewMarkerPage';
import ProfilePage from './pages/ProfilePage';
import PaymentPage from './pages/PaymentPage';
import Navbar from './components/Navbar';
import ModalDrawer from './components/ModalDrawer';

import { BrowserRouter, Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";

const PrivateRoute = () => {
    const { isAuthenticated } = useUser();
    console.log('PrivateRoute isAuthenticated:: ', isAuthenticated);
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
}

const App = () => {
    // const navigate = useNavigate();
    const { getUser, isAuthenticated, isLoading } = useUser();
    const user = getUser();

    return (
        isLoading ? <div> Is Loading..</div> : 
        <div>
            <Navbar user={user} />
            {
                    <Routes>
                        <Route path='/' element={ <PrivateRoute /> }>
                            {/* Specify private routes here  */}
                            <Route path='/' element={ <HomePage /> } />
                            <Route path="/drive" element={ <MapPage /> } />                                 
                            <Route path="/newMarker" element={<NewMarkerPage />} />
                            <Route path="/payment" element={<PaymentPage /> } /> 
                            <Route path="profile" element={<ProfilePage />}  />
                            <Route path='/test' element={ <div>test</div> }  />
                        </Route>
                        <Route path='/login' element={isAuthenticated ? <Navigate to="/" /> : <LoginPage/>}/>
                    </Routes>
            }
            <ModalDrawer />
        </div>
    );
};

export default App;
