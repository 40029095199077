import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
const ModalContext = createContext();

export const useModalProvider = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw Error('useModalProvider must be used within a ModalProvider');
  }
  return context;
};



const ModalProvider = ({ children }) => { 
    const [modalConfig, setModalConfig] = useState({});
    const [modalVisibility, setModalVisibility] = useState(false);

    const showModal = useCallback(() => {
        setModalVisibility(true);
    }, []);

    const hideModal = useCallback(() => {
        setModalVisibility(false);
    }, []);

    const value = useMemo(
        () => ({ modalConfig, setModalConfig, modalVisibility, showModal, hideModal }),
        [modalConfig, setModalConfig, modalVisibility, showModal, hideModal],
    );

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export default ModalProvider;
