import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import config from '../config';

const { types } = config.vehicles;

function AddCarForm({onSubmit}) {
  const { Formik } = formik;

  const schema = yup.object().shape({
    type: yup.string().required(),
    model: yup.string().required(),
    plate: yup.string().required(),
    seatsNo: yup.number().required()
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit || console.log}
      initialValues={{
        type: '',
        model: '',
        plate: '',
        seatsNo: -1
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Type</Form.Label>
                    {/* <Form.Control type="text" placeholder="Type" name="type" value={values.type} onChange={handleChange} isValid={touched.type && !errors.type} required /> */}

                    <Form.Select aria-label="choseVehicleType" name="type" onChange={handleChange} isValid={touched.type && !errors.type} required >
                            <option key="-1">Open this select menu</option>
                            {
                                Object.keys(types).map((typeId) => {
                                    return(
                                        <option key={typeId} value={typeId}>{ types[typeId] }</option>
                                    );
                                })
                            }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Please provide a vehicle type:              
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Model</Form.Label>
                    <Form.Control type="text" placeholder="Model" name="model" value={values.model} onChange={handleChange} isValid={touched.model && !errors.model} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a vehicle model.
                    </Form.Control.Feedback>
                </Form.Group>                

                <Form.Group as={Col} md="3" controlId="validationCustom03">
                    <Form.Label>License</Form.Label>
                    <Form.Control type="text" placeholder="Plate" name="plate" value={values.plate} onChange={handleChange} isValid={touched.plate && !errors.plate} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid plate.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="validationCustom04">
                    <Form.Label>Number of seats</Form.Label>
                    <Form.Control type="number" placeholder="Seats No." name="seatsNo" value={values.seatsNo} onChange={handleChange} isValid={touched.seatsNo && !errors.seatsNo} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid seat number.
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

          <Button type="submit">Save vehicle</Button>
        </Form>
      )}
    </Formik>
  );
}

export default AddCarForm;