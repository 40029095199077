import React, { useState, useEffect } from 'react';
import ModalWindow from '../components/ModalWindow';
import 'bootstrap/dist/css/bootstrap.min.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import useMapsJsApiLoader from '../context/mapsJsApiLoader.js';
import { useUser } from '../context/userContext.js'
import { useGrpc } from '../context/grpcContext.js';
import { useModalProvider } from '../context/ModalProviderContext.jsx';
import config from "../config.js";
import markersProto from '../protofiles/markers_grpc_web_pb';
import vehiclesProto from '../protofiles/vehicles_grpc_web_pb';
import AddCarForm from '../components/AddCarForm.jsx';
import ChooseVehicle from '../components/ChooseVehicle';

const { /* Empty, */ MarkerRequest, locationObj } = markersProto;
const { VehicleRequest, GetVehiclesByUserIdRequest } = vehiclesProto;

const NewMarkerPage  = () => {
    const { isAuthenticated, getUser, setUserIsLoggedOut }  = useUser();
    const user = getUser();
    const { isLoaded, /* loadError */ } = useMapsJsApiLoader();
    const { grpcClients, handleGrpcRequest } = useGrpc();
    const { setModalConfig, showModal, hideModal } = useModalProvider();

    const defaultState = {
        userId: -1,
        hasCar: false,
        carId: null,
        passangersNo: 0,
        location: {
            address: null,
            lat: null,
            lng: null
        },
        stops: [
        ],
        destination: {
            address: null,
            lat: null,
            lng: null
        },        
        arrivalDate: "",
        arrivalTime: ""
    };

    const [newMarker, setNewMarker] = useState(defaultState);
    const [vehicles, setVehicles] = useState([]);

    useEffect(
        () => {
          let grpcCallStatus;
    
          async function getVehicles(){
            try {
                if (user?.userid) {
                    const request = new GetVehiclesByUserIdRequest();
                    request.setUserid(user.userid);
                    const getVehiclesCb = (error, response) => {
                      if (error) {
                        console.log('getVehicles() error:: ', error);
                        throw error;
                      } else {
                        const {vehiclesList} = response.toObject();
                        console.log('getVehiclesCb() vehicles response::: ', vehiclesList);
                        setVehicles(vehiclesList);
      
                      }
                    };
          
                    grpcCallStatus = handleGrpcRequest(grpcClients.vehiclesClient, 'getVehiclesByUserId', request, true, {}, getVehiclesCb);
                }
            }
            catch(e) {
              console.log('errror::: ',e);
            }
          }
    
          getVehicles();
    
          return(() => {
            console.log('getVehiclesByUserId grpcCall status::: ', grpcCallStatus);
            if (grpcCallStatus) {
              grpcCallStatus.cancel();
            }
          });
        },
        [
            grpcClients?.vehiclesClient, handleGrpcRequest, user?.userid
        ]
    )

    const logout = () => {
        setUserIsLoggedOut();
        window.open(`${config.api}/auth/logout`, "_self");
    };    

    // const mockedDefaultState = {
    //     firstName: 'r',
    //     lastName: 'b',        
    //     hasCar: false,
    //     passangersNo: 0,
    //     location: {
    //         address: "Craiova, Romania",
    //         lat: 44.3301785,            
    //         lng: 23.7948808            
    //     },
    //     stops: [
    //         {
    //             address: "Crovna, Romania",
    //             id: "dbc81b30-cc27-4ebb-b6b8-a16e785c3352",
    //             lat: 44.3657707,
    //             lng: 23.652205
    //         }
    //     ],
    //     destination: {
    //         address: "Bucharest, Romania",
    //         lat: 44.4267674,
    //         lng: 26.1025384,           
    //     },        
    //     arrivalDate: "2024-01-18",
    //     arrivalTime: "01:01"
    // };

    const addNewMarkerHandler = () => {
        if (isAuthenticated) {
            const request = new MarkerRequest();
            // console.log('request Before::: ', request);
            console.log('addNewMarkerHandler() user::: ', user);
            request.setUserid(user.userid);

            request.setArrivaldate(newMarker.arrivalDate);
            request.setArrivaltime(newMarker.arrivalTime);
            
            const destination = new locationObj();
            destination.setAddress(newMarker.destination.address);
            destination.setLat(newMarker.destination.lat);
            destination.setLng(newMarker.destination.lng);
            request.setDestination(destination);
    
            const startLocation = new locationObj();
            startLocation.setAddress(newMarker.location.address);
            startLocation.setLat(newMarker.location.lat);
            startLocation.setLng(newMarker.location.lng);
            request.setLocation(startLocation);
            
            request.setPassangersno(newMarker.passangersNo);
            request.setHascar(newMarker.hasCar);
            request.setCarid(newMarker.carId);
            // request.setStopsList(newMarker.stops);
            const stopsList = newMarker.stops.map(stop => {
                const stopLocation = new locationObj();
                stopLocation.setAddress(stop.address);
                stopLocation.setLat(stop.lat);
                stopLocation.setLng(stop.lng);
                return stopLocation;
            });
            request.setStopsList(stopsList);
            // newMarker.stops.forEach(stop => {
            //     request.setStopsList(stop);
            // });
            // console.log('request AFTER::: ', request);

            const addMarkerCb = (err, response) => {
                if (err) {
                    console.error('AddMarker err::: ',err);
                    return;
                }
                console.log('addMarker emptyResponse::: ', response);
            };

            handleGrpcRequest(grpcClients.markersClient, 'addMarker', request, true, {}, addMarkerCb);
        }
        else {
            console.error('Error:: user should exist when authenticated');
            logout();
        }
    };
    
    const saveVehicleHandler = (e) => {
        
        try {
            console.log('saveVehicleHandler e::: ', e);
            const { type, model, plate, seatsNo } = e;
            
            const request = new VehicleRequest();
            request.setType(type);
            request.setModel(model);
            request.setPlate(plate);
            request.setSeatsno(seatsNo);

            const saveVehicleCb = (error, vehicleResponse) => {
                if (error) {
                    console.log('saveVehicleCb error:: ', error);
                    throw error;
                }
                console.log('saveVehicleHandler() vehicleResponse:: ', vehicleResponse);
                let vehicle = vehicleResponse.toObject();
                console.log('saved vehicle::: ', vehicle);
                setVehicles( [...vehicles, vehicle ] );
            };

            handleGrpcRequest(grpcClients.vehiclesClient, 'addVehicle', request, true, {}, saveVehicleCb);
            hideModal();
        }
        catch(e) {
            console.log('saveVehicleHandler errror::: ',e);
        }
    };

    const addVehicleHandler = (e) => {
        e.preventDefault();
        setModalConfig( 
            { 
                header: "Add new vehicle",
                content: <>
                    <div>
                        <AddCarForm onSubmit={ saveVehicleHandler }/>
                    </div>
                </>
            } 
        );
        showModal();
    };


    const onVehicleSelectHandler = (vid) => {
        setNewMarker({...newMarker, carId: vid})
    };

    return (
        <Container>
            <Row>
                <Col>
                    <Form>             
                        <Form.Group>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="carToggle"
                                label="Is your car with you?"
                                value={newMarker.hasCar}
                                onChange={() => {setNewMarker({...newMarker, hasCar: !newMarker.hasCar})}}
                            />               
                        </Form.Group>                             
                        <br/>

                        {
                            newMarker.hasCar
                                &&
                            <div>
                                    <div>
                                        <ChooseVehicle vehicles={vehicles} onVehicleSelect={onVehicleSelectHandler} addVehicleHandler={addVehicleHandler} showAddButton={false} />       
                                    </div>
                            </div>
                        }
                        <br/>

                        <Form.Group>
                            <Form.Label>how many passangers?</Form.Label>
                            <Form.Control 
                                type="number" 
                                value={newMarker.passangersNo}
                                onChange={(e) => {setNewMarker({...newMarker, passangersNo: e.target.value})}}
                            />
                        </Form.Group>                        
                        <Form.Group>
                            <Form.Label>Where are you?</Form.Label>
                            <ModalWindow isLoaded={isLoaded} cb={(location) => {setNewMarker({...newMarker, location})}}  type="setLocation"/>
                        </Form.Group>                               
                        <Form.Group>
                            <Form.Label>WHere do you need to go?</Form.Label>
                            <ModalWindow isLoaded={isLoaded} cb={(destination) => {setNewMarker({...newMarker, destination})}} type="setDestination" />
                        </Form.Group>                      
                        <Form.Group>
                            <Form.Label>Add a stop?</Form.Label>
                            <ModalWindow isLoaded={isLoaded} cb={(stops) => {setNewMarker({...newMarker, stops})}} stops={newMarker.stops} type="setStops"/>
                        </Form.Group>                             
                        <Form.Group>
                            <Form.Label>Arrival date?</Form.Label>
                            <Form.Control type="date" value={newMarker.arrivalDate} onChange={(e) => setNewMarker({...newMarker, arrivalDate: e.target.value})} />
                        </Form.Group>                
                        <Form.Group>
                            <Form.Label>Arrival time?</Form.Label>
                            <Form.Control type="time"  value={newMarker.arrivalTime} onChange={(e) => setNewMarker({...newMarker, arrivalTime: e.target.value})} />
                        </Form.Group>                                                                                                
                        <Button variant="secondary" onClick={addNewMarkerHandler}>Add new marker</Button>
                    </Form>                
                </Col>
                
            </Row>
            <br/>
            <Container>
                {
                    (newMarker?.location || newMarker?.stops?.length > 0 || newMarker?.destination) &&   
                    <Row>
                        <Col>
                            <h3>Itinerariu</h3>
                        </Col>
                    </Row>                 
                }

                {
                    newMarker?.location?.address && 
                    <Row className="pickUpLocation">
                        <Col>
                            <h4>{newMarker.location.address}</h4>
                        </Col>
                    </Row>                
                }

                {
                    (newMarker?.stops?.length > 0) && (newMarker.stops.map(stop => {
                        console.log('any stops?')
                        return(
                            <Row className="stops" key={`${stop.id}}`}>
                                <Col>
                                    <h4>{stop.address}</h4>
                                </Col>
                            </Row>                                  
                        )
                    }))
                }

                {
                    newMarker?.destination?.address && 
                    <Row className="destination">
                        <Col>
                            <h4>{newMarker.destination.address}</h4>
                        </Col>
                    </Row>                
                }                
            </Container>    
        </Container>  
    );
};

export default NewMarkerPage;