import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import VehicleDetails from './VehicleDetails';
import Button from 'react-bootstrap/Button';

function ChooseVehicle( { vehicles, onVehicleSelect, addVehicleHandler, showAddButton=true, deleteEnabled=true } ) {
  const onSelectVehicle = (vid) => {
    console.log('ChooseVehicle onSelectVehicle() vid::: ', vid);
    onVehicleSelect && onVehicleSelect(vid);
  }

  const onAddVehicle =(vehicle) => {
    addVehicleHandler && addVehicleHandler(vehicle);
  };

  const onDeleteVehicle = (vid) => {
    vid.stopPropagation();
    console.log('ChooseVehicle onDeleteVehicle()::: Not impletemented -  draft ');
    console.log('ChooseVehicle onDeleteVehicle()::: ', vid);
  };

  const vehiclesListGroupItems = vehicles.map(v => {
    const {model, id, disabled} = v;
    console.log('disalbed::: ', disabled);
    return(
      <ListGroup.Item action href={`#link${id}`} key={`#link${id}`} onClick={() => onSelectVehicle(id)} as="li" disabled={ disabled ? true : false } >
        <span>
            {model}
        </span>   
        <span>
        { deleteEnabled && <Link onClick={onDeleteVehicle} > del </Link> }
        </span>

      </ListGroup.Item>
    );
  });


  const vehiclesTabPaneContent = vehicles.map((v,i) => {
    const {id, userid, model, type, plate, seatsno} = v;
    return(
      <Tab.Pane eventKey={`#link${id}`} key={`#link${id}`}>
        {/* <h4> { model } </h4>

        <span> Type: { vehicleTypes[type] } </span>
        <br/>
        <span> Plate: { plate } </span>
        <br/>
        <span> Number of seats: { seatsno } </span> */}
        <VehicleDetails model={model} type={type} plate={plate} seatsNo={seatsno}/>
      </Tab.Pane>
    );
  })


  return (
    <div>
      {
        <div>
          {
            ( !vehicles.length || showAddButton ) && 
            <div>
              <span>You have no vehicles.</span>
              <Button onClick={onAddVehicle}>Add vehicle</Button>
            </div>
          }


          <div>
            {
              vehicles.length && 
              <div>
                <span>Select vehicle:</span>
                <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                  <Row>
                    
                    <Col sm={4}>
                      <ListGroup>
                        {
                          vehiclesListGroupItems
                        }
                      </ListGroup>
                    </Col>

                    <Col sm={8}>
                      <Tab.Content>
                        {
                        vehiclesTabPaneContent
                        }
                      </Tab.Content>
                    </Col>

                  </Row>
                </Tab.Container> 
              </div>
            }
     
          </div>
        </div>
      }
    </div>
  );
}

export default ChooseVehicle;