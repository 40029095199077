/**
 * @fileoverview gRPC-Web generated client stub for offers
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protofiles/offers.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.offers = require('./offers_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.offers.OffersServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.offers.OffersServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.offers.OfferRequest,
 *   !proto.offers.OfferResponse>}
 */
const methodDescriptor_OffersService_AddOffer = new grpc.web.MethodDescriptor(
  '/offers.OffersService/AddOffer',
  grpc.web.MethodType.UNARY,
  proto.offers.OfferRequest,
  proto.offers.OfferResponse,
  /**
   * @param {!proto.offers.OfferRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.offers.OfferResponse.deserializeBinary
);


/**
 * @param {!proto.offers.OfferRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.offers.OfferResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.offers.OfferResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.offers.OffersServiceClient.prototype.addOffer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/offers.OffersService/AddOffer',
      request,
      metadata || {},
      methodDescriptor_OffersService_AddOffer,
      callback);
};


/**
 * @param {!proto.offers.OfferRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.offers.OfferResponse>}
 *     Promise that resolves to the response
 */
proto.offers.OffersServicePromiseClient.prototype.addOffer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/offers.OffersService/AddOffer',
      request,
      metadata || {},
      methodDescriptor_OffersService_AddOffer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.offers.GetMarkerByUserIdAndMarkerIdRequest,
 *   !proto.offers.OffersResponse>}
 */
const methodDescriptor_OffersService_GetMarkerByUserIdAndMarkerId = new grpc.web.MethodDescriptor(
  '/offers.OffersService/GetMarkerByUserIdAndMarkerId',
  grpc.web.MethodType.UNARY,
  proto.offers.GetMarkerByUserIdAndMarkerIdRequest,
  proto.offers.OffersResponse,
  /**
   * @param {!proto.offers.GetMarkerByUserIdAndMarkerIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.offers.OffersResponse.deserializeBinary
);


/**
 * @param {!proto.offers.GetMarkerByUserIdAndMarkerIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.offers.OffersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.offers.OffersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.offers.OffersServiceClient.prototype.getMarkerByUserIdAndMarkerId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/offers.OffersService/GetMarkerByUserIdAndMarkerId',
      request,
      metadata || {},
      methodDescriptor_OffersService_GetMarkerByUserIdAndMarkerId,
      callback);
};


/**
 * @param {!proto.offers.GetMarkerByUserIdAndMarkerIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.offers.OffersResponse>}
 *     Promise that resolves to the response
 */
proto.offers.OffersServicePromiseClient.prototype.getMarkerByUserIdAndMarkerId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/offers.OffersService/GetMarkerByUserIdAndMarkerId',
      request,
      metadata || {},
      methodDescriptor_OffersService_GetMarkerByUserIdAndMarkerId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.offers.GetOffersStatsForMarkerIdByUserIdRequest,
 *   !proto.offers.GetOffersStatsForMarkerIdByUserIdResponse>}
 */
const methodDescriptor_OffersService_GetOffersStatsForMarkerIdByUserId = new grpc.web.MethodDescriptor(
  '/offers.OffersService/GetOffersStatsForMarkerIdByUserId',
  grpc.web.MethodType.UNARY,
  proto.offers.GetOffersStatsForMarkerIdByUserIdRequest,
  proto.offers.GetOffersStatsForMarkerIdByUserIdResponse,
  /**
   * @param {!proto.offers.GetOffersStatsForMarkerIdByUserIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.offers.GetOffersStatsForMarkerIdByUserIdResponse.deserializeBinary
);


/**
 * @param {!proto.offers.GetOffersStatsForMarkerIdByUserIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.offers.GetOffersStatsForMarkerIdByUserIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.offers.GetOffersStatsForMarkerIdByUserIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.offers.OffersServiceClient.prototype.getOffersStatsForMarkerIdByUserId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/offers.OffersService/GetOffersStatsForMarkerIdByUserId',
      request,
      metadata || {},
      methodDescriptor_OffersService_GetOffersStatsForMarkerIdByUserId,
      callback);
};


/**
 * @param {!proto.offers.GetOffersStatsForMarkerIdByUserIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.offers.GetOffersStatsForMarkerIdByUserIdResponse>}
 *     Promise that resolves to the response
 */
proto.offers.OffersServicePromiseClient.prototype.getOffersStatsForMarkerIdByUserId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/offers.OffersService/GetOffersStatsForMarkerIdByUserId',
      request,
      metadata || {},
      methodDescriptor_OffersService_GetOffersStatsForMarkerIdByUserId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.offers.GetOffersByUserIdRequest,
 *   !proto.offers.GetOffersByUserIdResponse>}
 */
const methodDescriptor_OffersService_GetOffersByUserId = new grpc.web.MethodDescriptor(
  '/offers.OffersService/GetOffersByUserId',
  grpc.web.MethodType.UNARY,
  proto.offers.GetOffersByUserIdRequest,
  proto.offers.GetOffersByUserIdResponse,
  /**
   * @param {!proto.offers.GetOffersByUserIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.offers.GetOffersByUserIdResponse.deserializeBinary
);


/**
 * @param {!proto.offers.GetOffersByUserIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.offers.GetOffersByUserIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.offers.GetOffersByUserIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.offers.OffersServiceClient.prototype.getOffersByUserId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/offers.OffersService/GetOffersByUserId',
      request,
      metadata || {},
      methodDescriptor_OffersService_GetOffersByUserId,
      callback);
};


/**
 * @param {!proto.offers.GetOffersByUserIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.offers.GetOffersByUserIdResponse>}
 *     Promise that resolves to the response
 */
proto.offers.OffersServicePromiseClient.prototype.getOffersByUserId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/offers.OffersService/GetOffersByUserId',
      request,
      metadata || {},
      methodDescriptor_OffersService_GetOffersByUserId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.offers.WinningOfferRequest,
 *   !proto.offers.WinningOfferResponse>}
 */
const methodDescriptor_OffersService_SetWinningOffer = new grpc.web.MethodDescriptor(
  '/offers.OffersService/SetWinningOffer',
  grpc.web.MethodType.UNARY,
  proto.offers.WinningOfferRequest,
  proto.offers.WinningOfferResponse,
  /**
   * @param {!proto.offers.WinningOfferRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.offers.WinningOfferResponse.deserializeBinary
);


/**
 * @param {!proto.offers.WinningOfferRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.offers.WinningOfferResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.offers.WinningOfferResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.offers.OffersServiceClient.prototype.setWinningOffer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/offers.OffersService/SetWinningOffer',
      request,
      metadata || {},
      methodDescriptor_OffersService_SetWinningOffer,
      callback);
};


/**
 * @param {!proto.offers.WinningOfferRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.offers.WinningOfferResponse>}
 *     Promise that resolves to the response
 */
proto.offers.OffersServicePromiseClient.prototype.setWinningOffer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/offers.OffersService/SetWinningOffer',
      request,
      metadata || {},
      methodDescriptor_OffersService_SetWinningOffer);
};


module.exports = proto.offers;

