/**
 * @fileoverview gRPC-Web generated client stub for auth
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protofiles/auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.auth = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.FacebookLoginRequest,
 *   !proto.auth.FacebookLoginResponse>}
 */
const methodDescriptor_AuthService_FacebookLogin = new grpc.web.MethodDescriptor(
  '/auth.AuthService/FacebookLogin',
  grpc.web.MethodType.UNARY,
  proto.auth.FacebookLoginRequest,
  proto.auth.FacebookLoginResponse,
  /**
   * @param {!proto.auth.FacebookLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.FacebookLoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.FacebookLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.FacebookLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.FacebookLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.facebookLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/FacebookLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_FacebookLogin,
      callback);
};


/**
 * @param {!proto.auth.FacebookLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.FacebookLoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.facebookLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/FacebookLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_FacebookLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.GoogleLoginRequest,
 *   !proto.auth.GoogleLoginResponse>}
 */
const methodDescriptor_AuthService_GoogleLogin = new grpc.web.MethodDescriptor(
  '/auth.AuthService/GoogleLogin',
  grpc.web.MethodType.UNARY,
  proto.auth.GoogleLoginRequest,
  proto.auth.GoogleLoginResponse,
  /**
   * @param {!proto.auth.GoogleLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GoogleLoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.GoogleLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GoogleLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GoogleLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.googleLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/GoogleLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_GoogleLogin,
      callback);
};


/**
 * @param {!proto.auth.GoogleLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GoogleLoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.googleLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/GoogleLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_GoogleLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.EmptyRequest,
 *   !proto.auth.SessionResponse>}
 */
const methodDescriptor_AuthService_GetSession = new grpc.web.MethodDescriptor(
  '/auth.AuthService/GetSession',
  grpc.web.MethodType.UNARY,
  proto.auth.EmptyRequest,
  proto.auth.SessionResponse,
  /**
   * @param {!proto.auth.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.SessionResponse.deserializeBinary
);


/**
 * @param {!proto.auth.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.SessionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.SessionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.getSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/GetSession',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetSession,
      callback);
};


/**
 * @param {!proto.auth.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.SessionResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.getSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/GetSession',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.EmptyRequest,
 *   !proto.auth.LogoutResponse>}
 */
const methodDescriptor_AuthService_Logout = new grpc.web.MethodDescriptor(
  '/auth.AuthService/Logout',
  grpc.web.MethodType.UNARY,
  proto.auth.EmptyRequest,
  proto.auth.LogoutResponse,
  /**
   * @param {!proto.auth.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LogoutResponse.deserializeBinary
);


/**
 * @param {!proto.auth.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LogoutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LogoutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout,
      callback);
};


/**
 * @param {!proto.auth.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LogoutResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout);
};


module.exports = proto.auth;

