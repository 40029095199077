import config from '../config';
const {types: vehicleTypes} = config.vehicles;

const VehicleDetails = ({model, type, plate, seatsNo}) => {
    return(
        <div>
            <h4> { model } </h4>

            <span> Type: { vehicleTypes[type] } </span>
            <br/>
            <span> Plate: { plate } </span>
            <br/>
            <span> Number of seats: { seatsNo } </span>
        </div>

    );
};

export default VehicleDetails;