import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { useUser } from './userContext';
import { useGrpc } from '../context/grpcContext';
import markersProto from '../protofiles/markers_grpc_web_pb';
import EventBus from "../utils/eventBus";

const { MarkersWithOffersRequest } = markersProto;

const ReceivedOffersContext = createContext();

export const useReceivedOffersContext = () => {
  const context = useContext(ReceivedOffersContext);
  if (context === undefined) {
    throw Error('useReceivedOffersContext must be used within a ReceivedOffersContext');
  }
  return context;
};

const ReceivedOffersProvider = ({ children }) => {
    const [markersWithOffers, setContextMarkersWithOffers] = useState([]);
    const [newReceivedOfferStatus, setNewReceivedOfferStatus] = useState(false);
    const [myNewWinningOffer, setMyWinningOffer] = useState(false);
    const [newWinningOffer, setNewWinningOffer] = useState(false);

    const {grpcClients, handleGrpcRequest} = useGrpc();
    const { getUser, isLoading } = useUser();
    const user = getUser();
    
    useEffect(
        () => {
            let grpcCallStatus;

            async function grpcMarkersWithOffersByUserId(){
                try {
                    if (user?.userid) {
                        const userId = user.userid;
                        const markersWithOffersRequest = new MarkersWithOffersRequest();
                        markersWithOffersRequest.setUserid(userId);
            
                        const getMarkersWithOffersByUserIdCb = (error, markersResponse) => {
                            if (error) {
                                console.log('grpcMarkersWithOffersByUserId error:: ', error);
                                throw error;
                            } else {
                                let {markersList} = markersResponse.toObject();
                                console.log('grpcMarkersWithOffersByUserId initial markersList::: ', markersList);
                                setContextMarkersWithOffers(markersList);
                            }
                        };
            
                        grpcCallStatus = handleGrpcRequest(grpcClients.markersClient, 'getMarkersWithOffersByUserId', markersWithOffersRequest, true, {}, getMarkersWithOffersByUserIdCb);
                        return grpcCallStatus;
                    }
                }
                catch(e) {
                    console.log('grpcMarkersWithOffersByUserId errror::: ',e);
                    throw e;
                }
            }

            if (!isLoading) {
                console.log('ReceivedOffersContext grpcMarkersWithOffersByUserId() isLoading::: ', isLoading);
                grpcMarkersWithOffersByUserId();
            }

            return () => {
                if (grpcCallStatus) {
                    grpcCallStatus.cancel();
                }
            }
        },
        [
            grpcClients?.markersClient, 
            handleGrpcRequest,
            user,
            isLoading,
            newWinningOffer
        ]
    );

    const addNewOffer = useCallback((offerToadd) =>{
        console.log('offerToadd to received offers context::: ', offerToadd);
        const markerIndex = markersWithOffers.findIndex(marker => marker.id === offerToadd.markerId);
        if (markerIndex > -1 ) {
            const newMarkersWithOffers = [...markersWithOffers];
            const marker = newMarkersWithOffers[markerIndex];
            console.log(`marker to add offer found:: `, marker);
            const { offersList } = marker;
            console.log(`initial offersList.length:: `, offersList.length);
            
            //convert case from camelCase to all lowercase
            const { userId: userid, markerId:markerid, carId:carid, amount, id, vehicle} = offerToadd;

            offersList.push({userid, markerid, carid, amount, id, vehicle, type:'new'});

            marker.offersList = offersList;
            newMarkersWithOffers[markerIndex] = marker;
            console.log(`final offersList.length:: `, newMarkersWithOffers[markerIndex].offersList.length);

            //emit event when 

            setContextMarkersWithOffers(newMarkersWithOffers);
            setNewReceivedOfferStatus(true);
            console.log('context was set');
        }
        // else {
        //     throw new Error('Cannot add offer because marker not found.')
        // }
    }, [markersWithOffers]);

    const setWinningOffer = useCallback((offerDetails) => {
        console.log('set Offer as winning offerDetails:: ', offerDetails);
        EventBus.emit("refreshSentOffers", offerDetails );
        setNewWinningOffer(offerDetails);
        if (offerDetails.offerUserId === user.userid) {
            setMyWinningOffer(true);
        }
    }, [user?.userid]);

    const getContextMarkersWithOffers = useCallback(() => {
        return markersWithOffers;
    },[ markersWithOffers ]);



    const value = useMemo(
        () => ({ markersWithOffers, setContextMarkersWithOffers, getContextMarkersWithOffers, addNewOffer, setNewReceivedOfferStatus, newReceivedOfferStatus, setWinningOffer, newWinningOffer, setNewWinningOffer, myNewWinningOffer, setMyWinningOffer }),
        [markersWithOffers, setContextMarkersWithOffers, getContextMarkersWithOffers, addNewOffer, setNewReceivedOfferStatus, newReceivedOfferStatus, setWinningOffer, newWinningOffer, setNewWinningOffer, myNewWinningOffer, setMyWinningOffer],
    );

    return <ReceivedOffersContext.Provider value={value}>{children}</ReceivedOffersContext.Provider>;
};

export default ReceivedOffersProvider;
