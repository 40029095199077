import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function GenericModal({children, header, show, handleClose, handleSave, }) {
  return (
    <>
     <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        {
          handleSave && 
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        }
      </Modal>
    </>
  );
}

export default GenericModal;