import { useUser } from '../context/userContext';
import { useGrpc } from '../context/grpcContext';
import { Link } from 'react-router-dom';
import MyCompletedSolicitations from '../components/MyCompletedSolicitations';
import { useEffect, useState } from 'react';
import { useReceivedOffersContext } from '../context/receivedOffersContext';

const markersProto = require('../protofiles/markers_grpc_web_pb');
const  { GetMyCompletedSolicitationsRequest } = markersProto;

const HomePage = () => {
    const { getUser } = useUser();
    const user = getUser();
    const {grpcClients, handleGrpcRequest} = useGrpc();

    const [completedSolicitations , setCompletedSolicitations] = useState();
    const { newWinningOffer } = useReceivedOffersContext();


    useEffect(
        () => {
            let grpcCallStatus;

            async function getMyCompletedSolicitations(){
                try {
                    if (user?.userid) {
                        const userId = user.userid;
                        const request = new GetMyCompletedSolicitationsRequest();
                        request.setUserid(userId);
            
                        const getMyCompletedSolicitationsCb = (error, markersResponse) => {
                            if (error) {
                                console.log('getMyCompletedSolicitationsCb error:: ', error);
                                throw error;
                            } else {
                                let {markersList} = markersResponse.toObject();
                                console.log('getMyCompletedSolicitationsCb solicitations::: ', markersList);
                                setCompletedSolicitations(markersList);
                            }
                        };
            
                        grpcCallStatus = handleGrpcRequest(grpcClients.markersClient, 'getMyCompletedSolicitations', request, true, {}, getMyCompletedSolicitationsCb);
                        return grpcCallStatus;
                    }
                }
                catch(e) {
                    console.log('getMyCompletedSolicitations errror::: ',e);
                    throw e;
                }
            }

            getMyCompletedSolicitations();

            return () => {
                if (grpcCallStatus) {
                    grpcCallStatus.cancel();
                }
            }
        },
        [ grpcClients?.markersClient, handleGrpcRequest, user?.userid, newWinningOffer ]
    );

    return (
        <div>
            <div>
                <p>Welcome, {user.name}</p>
                <p>Email: {user.email}</p>

                <br/>
                <Link to="/drive">Drive</Link>
                <br/>
                <Link to="/newMarker">Add new marker</Link>
                <br/>
            </div>

            <div>
                <MyCompletedSolicitations data={completedSolicitations} />
            </div>
        </div>
    );
}

export default HomePage;