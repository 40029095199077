import { useJsApiLoader } from '@react-google-maps/api';
import { maps } from '../config';


export default function useMapsJsApiLoader(){
  const { isLoaded,loadError } = useJsApiLoader({
    // version: 3.57,
    version: 3.57,
    id: 'google-map-script',
    googleMapsApiKey: maps.gMapsApiKey,
    libraries: maps.gMapsLibraries
  });

  return {isLoaded, loadError};
}
    