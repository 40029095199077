import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
const GrpcContext = createContext();

export const useGrpc = () => {
  const context = useContext(GrpcContext);
  if (context === undefined) {
    throw Error('useGrpc must be used within a GrpcProvider');
  }
  return context;
};

const GrpcProvider = ({ children, grpcClients }) => {

  const handleGrpcRequest = useCallback(
    (client, method, request, addSessionToMetadata=false, metadata={}, cb) => {
      let grpcCallStatus;
      try {
          console.log('handleGrpcRequest() method::: ', method, ' cb:: ', cb);
  
          if (addSessionToMetadata) {
              const sessionId = localStorage.getItem('sessionid');
              if (sessionId) {
                  metadata = {
                      ...metadata,
                      token: sessionId,
                  };
              }
          }
          // console.log('handleGrpcRequest method:: ', method, ' metadata:: ', metadata, ' request:: ', request);
          grpcCallStatus = client[method](request, metadata, cb);

          return grpcCallStatus;
      }
      catch(e) {
        console.log('handleGrpcRequest errror::: ',e);
        grpcCallStatus?.cancel();
      }    
  },
    []
  );
  

  const value = useMemo(
    () => ({ grpcClients, handleGrpcRequest }),
    [grpcClients, handleGrpcRequest],
  );

  return <GrpcContext.Provider value={value}>{children}</GrpcContext.Provider>;
};

export default GrpcProvider;
