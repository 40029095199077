import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { io } from "socket.io-client";
import { useUser } from './userContext';
import { useReceivedOffersContext } from './receivedOffersContext';

const SOCKET_SERVER_URL = "http://192.168.1.1:3333"; // Replace with your server URL
const SocketIoClientContext = createContext();

export const useSocketIoClient = () => {
  const context = useContext(SocketIoClientContext);
  if (context === undefined) {
    throw Error('useSocketIoClient must be used within a SocketIoClientProvider');
  }
  return context;
};

const SocketIoClientProvider = ({ children }) => {
  const {getUser, isLoading} = useUser();
  const user = getUser();
  const {addNewOffer, setWinningOffer} = useReceivedOffersContext();

  const [socketIoClient, setSocketIoClient] = useState(null);

  //set a cookie so we can send it with an socket.io request...could not make it work yet, that is why we use the "auth" key
  // document.cookie = "authToken=my-auth-token; path=/; samesite=lax";

  useEffect(() => {
    let socket;

    function onConnect() {
          // socket.emit(`checkOffers`, { payload: {} }, val => {
          //   console.log('emit val::: ', val);
          // });      
          console.log('Socket.io Connected', socket?.id);
    }

    function onOfferEvent(data, callback) {
      const {payload, message} = data;
      // console.log('onNewOfferEvent*( new offer payload::: ', payload);
      if (message === 'offerAdded' ) {
        addNewOffer(payload);
        callback && callback( {status: "Offer notification received."} );
      }
    }

    function onGeneralOfferEvent(data, callback) {
      const {payload, message} = data;
      if (message === 'offerWon' ) {
        setWinningOffer(payload);
        callback && callback( {status: "Offer was set as winner."} );
      }
    }

    function onDisconenct(reason){
      if (socket.active) {
        console.log('temporary disconnection, Socket.io will automatically try to reconnect');
      } else {
        // the connection was forcefully closed by the server or the client itself
        // in that case, `socket.connect()` must be manually called in order to reconnect
        console.log('Socket.Io Disconnect Reason::', reason);
      }
    }

    function onClients(clients) {
      console.log("Connected clients:", clients);
    }

    if (!isLoading) {
      console.log('Socket.io Connecting');
      if (user?.userid) {
        const userId = user.userid;
        console.log(`adding new offer for ${userId}:::`);
        const token = localStorage.getItem('sessionid');
        socket = io(SOCKET_SERVER_URL, {
          auth: { token },
          // transports: ["websocket"], // Use WebSocket transport
          withCredentials: true,     // Send credentials like cookies
          // autoConnect: false          //Manual socket.connect()
        });

        socket.on("connect", onConnect);    
        socket.on(`offers`, onGeneralOfferEvent)
        socket.on(`offers/${userId}`, onOfferEvent);
        socket.on("disconnect", onDisconenct);      
        socket.on("clients", onClients);
        
        setSocketIoClient(socket);
      }
    }

    // Clean up the connection when the component unmounts
    return () => {
      console.log('Clean up the Socket.io connection when the SocketIoClientProvider component unmounts');
      socket?.disconnect();
    };
  }, 
  [
    user,
    isLoading,
    addNewOffer,
    setWinningOffer
  ]);

  const value = useMemo(
    () => ({ socketIoClient }),
    [socketIoClient],
  );

  return <SocketIoClientContext.Provider value={value}>{children}</SocketIoClientContext.Provider>;
};

export default SocketIoClientProvider;
