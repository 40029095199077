import { Accordion, ListGroup, Badge} from 'react-bootstrap';
import { useCallback } from 'react';
import VehicleDetails from './VehicleDetails';

const MyCompletedSolicitations = ({data}) => {
    console.log('MyCompletedSolicitations data prop::: ', data);

    const processPayload = useCallback((payload) => {
        let accordionContent;
        if (payload?.length) {
          accordionContent = payload.map(markerWithOffers => {
            console.log('MyCompletedSolicitations processPayload::: ', markerWithOffers);
  
            const eventKey = markerWithOffers.id;
            // const markerLocation = markerWithOffers.location;
            // const {lat: markerLat, lng: markerLng} = markerLocation;
  
            const key = eventKey;
            const headerContent = <span>{markerWithOffers.location.address} - {markerWithOffers.destination.address}</span>;
            const offerList = markerWithOffers.offersList;
            let offersListContent = <div>No offers for this marker</div>;
    
            let shouldHighlight = false;
    
            if (offerList.length > 0) {
              offersListContent = markerWithOffers.offersList
              .filter(offer => offer.status === 'winner')
              .map((offer,i) => {
                const {id, userid, carid, amount, /* markerid, */ type, status, vehicle } = offer; 
    
                if (status === "winner") {
                  shouldHighlight = true;
                }
    
                return (
                  <ListGroup.Item key={ `o${id}` } variant={shouldHighlight ? "secondary" : ""} as="li" >
                    <span> # {userid} Car: {carid}  </span>
                    <br/>
                    {      
                    vehicle && 
                    <VehicleDetails 
                      model={ vehicle.model }
                      type={ vehicle.type }
                      plate={ vehicle.plate } 
                      seatsNo={ vehicle.seatsno }
                    />
                  }  
                  <span>Amount: {amount} </span>
                  <br/>
                  <span>{`Status: ${ status }`  }</span>
                  </ListGroup.Item>
                );
  
              });
            }   
    
            return <Accordion.Item eventKey={eventKey} key={`solicitationMarker${key}`} >
                    <Accordion.Header >
                      {headerContent} 
                      <Badge bg="primary" pill>
                        {offersListContent.length || 0}
                      </Badge>
                    </Accordion.Header>
                    <Accordion.Body >
                      <ListGroup as="ol" numbered key={`solicitationOffers${markerWithOffers.id}`}>
                        {offersListContent}
                        </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
          });

        }
        return accordionContent;
      }, 
      []
    );


    return(
        <div>
            {/* 'This place will be filled with details regarding your completed requests. //getMyCompletedRequests' */}
            <p> Completed Solicitations </p>
            <Accordion>
                { processPayload(data) }
            </Accordion>
        </div>

    );
};

export default MyCompletedSolicitations;